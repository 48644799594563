import React, { FormEvent, useEffect, useState } from 'react';
import Panel from "../../../common/Panel";
import { IDropdownOption, Spinner, SpinnerSize, Toggle } from "office-ui-fabric-react";
import FormSelectField from "../../../common/FormSelectField";
import { AddonResourceCategoriesRelationDto, AddonType, ResourceCategoryAssigningMethodType, State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { connect, ConnectedProps } from "react-redux";
import { selectCurrentAddon, selectIsLoadingAddon } from "../../../../reducers/addons";
import { updateAddonAction } from '../../../../actions/addon-action';
import './controls.scss'
import { getAllResourceCategoriesAction } from '../../../../actions/resources-action';
import { selectAllResourceCategories } from '../../../../reducers/resources';
import { getResourceRelationsForAddonAction, updateResourceRelationsForAddonAction } from '../../../../actions/resource-relations-actions';
import { selectResourceRelations } from '../../../../reducers/addon-resource-relations';
import { v4 as uuid } from 'uuid';

const mapDispatchToProps = {
  updateAddon: updateAddonAction,
  getAllResourceCategories: getAllResourceCategoriesAction,
  updateResourceRelationsForAddon: updateResourceRelationsForAddonAction,
  getResourceRelationsForAddon: getResourceRelationsForAddonAction
};

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentAddon: selectCurrentAddon(state),
  isLoadingAddon: selectIsLoadingAddon(state),
  allResourceCategories: selectAllResourceCategories(state),
  resourceRelations: selectResourceRelations(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AddonsControls = ({
  readonly,
  currentAddon,
  updateAddon,
  isLoadingAddon,
  getAllResourceCategories,
  allResourceCategories,
  updateResourceRelationsForAddon,
  resourceRelations,
  getResourceRelationsForAddon
}: Props) => {
  const [selectedResourceCategory, setSelectedResourceCategory] = useState<string | null>(null);

  useEffect(() => {
    getAllResourceCategories();
  }, [getAllResourceCategories])

  useEffect(() => {
    if (currentAddon && currentAddon?.addonType === AddonType.resourceBased) {
      getResourceRelationsForAddon({
        addonId: currentAddon.id
      });
    }
  }, [currentAddon, getResourceRelationsForAddon]);

  useEffect(() => {
    if (resourceRelations.length > 0) {
      setSelectedResourceCategory(resourceRelations[0].categoryId);
    }
  }, [resourceRelations]);

  const setAddonStatus = () => {
    if (currentAddon) {
      updateAddon({ ...currentAddon, status: !currentAddon.status })
    }
  }

  const setAddonRequired = () => {
    if (currentAddon) {
      updateAddon({ ...currentAddon, required: !currentAddon.required })
    }
  }

  const setAddonAllowCustomerNotes = () => {
    if (currentAddon) {
      updateAddon({ ...currentAddon, allowCustomerNotes: !currentAddon.allowCustomerNotes })
    }
  }

  const setMatchGuestCount = () => {
    if (currentAddon) {
      updateAddon({ ...currentAddon, matchGuestCount: !currentAddon.matchGuestCount })
    }
  }

  const setAddonResourceCategory = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option && currentAddon) {
      setSelectedResourceCategory(option.key as string);
      const selectedCategory = allResourceCategories.find(cat => cat.id === option.key);
      const resources = selectedCategory?.resources || [] as any[];
      updateResourceRelationsForAddon({
        addonId: currentAddon.id,
        relations: [{
          id: uuid(),
          count: 1,
          categoryId: option.key as string,
          categoryName: option.text as string,
          assigningMethod: ResourceCategoryAssigningMethodType.matchGuestCount,
          categoryActive: true,
          priority: 1,
          primary: true,
          resources,
          resourceName: "",
          addonId: currentAddon.id,
        }],
      });
    }
  }

  return (
    <Panel
      title={
        <div className='h6'>Add on Controls</div>
      }
      className='addon-controls panel-shift'
    >
      {isLoadingAddon && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <Toggle
        inlineLabel
        checked={currentAddon?.status}
        onChange={setAddonStatus}
        disabled={readonly}
        label={
          <span className='body2'>Add on is Active</span>
        }
      />
      <Toggle
        inlineLabel
        checked={currentAddon?.required}
        onChange={setAddonRequired}
        disabled={readonly}
        label={
          <span className='body2'>Add on is Required</span>
        }
      />
      <Toggle
        inlineLabel
        checked={currentAddon?.allowCustomerNotes}
        onChange={setAddonAllowCustomerNotes}
        disabled={readonly}
        label={
          <span className='body2'>Allow Customer Notes</span>
        }
      />
      <Toggle
        inlineLabel
        checked={currentAddon?.matchGuestCount}
        onChange={setMatchGuestCount}
        disabled={readonly}
        label={
          <span className='body2'>Match Guest Count</span>
        }
      />
      {currentAddon?.addonType === AddonType.resourceBased ? <div className="body2">
        <FormSelectField
          label="Add on Resource Category"
          selectedKey={selectedResourceCategory}
          options={allResourceCategories.map(category => ({ key: category.id, text: category.name }))}
          onChange={setAddonResourceCategory}
          disabled={readonly}
        />
      </div> : null}
    </Panel>
  );
};

export default connector(AddonsControls);

