import {
  ReservationStatus,
  Pricing,
  CurrencyType,
  CheckInStatus,
  BookedStatus,
} from '../entities/enums';
import { GuestsWithAgeGroup } from './guestsWithAgeGroup';
import { ReservationActivityDto } from './reservationActivity.dto';
import { ReservationAddonDto } from './reservationAddon.dto';
import { ReservationIntegrationDto } from './reservationIntegration.dto';
import { ReservationResourceDto } from './reservationResource.dto';
import { TransactionSettingDto } from './transactionSettings.dto';

export interface ReservationSettingsDto {
  id: string;
  number: string;
  resNumber: string;
  date: string;
  slots: number;
  duration: number;
  guests: number;
  guestsWithAgeGroups?: GuestsWithAgeGroup[];
  lanes: number;
  venueId: string;
  price: number;
  deposit: number;
  depositParam: number;
  depositType: string;
  textBox: string;
  startSlot: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  occasion?: string;
  guestDetail?: string;
  customNotes?: string;
  venueName: string;
  venueTimeZone: string;
  transactionId?: string;
  packageId?: string;
  packageName?: string;
  preBufferTime: number;
  bufferTime: number;
  referenceNumber?: string;
  posCheckNumber?: string;
  startTime: number;
  endTime: Date;
  tax: number;
  serviceFee: number;
  customFees: string;
  discount: number;
  pricing: Pricing;
  timeSlotDuration: number;
  createdAt: Date;
  status: ReservationStatus;
  timeSlotShifting: number;
  customFieldsInformation?: string;
  currency: CurrencyType;
  giftCardPaid: number;
  giftCardNumbers: string;
  discountAmount: number;
  discountCodes?: string;
  modificationFee: number;
  modified: boolean;
  addonsPrice: number;
  addons: ReservationAddonDto[];
  hasAddons: boolean;
  resources: ReservationResourceDto[];
  integrations: ReservationIntegrationDto[];
  customTaxes: string;
  addonCustomTaxes: string;
  customFeesTaxes: string;
  subscribeToOffers?: boolean;
  adminNotes?: string;
  createdByAdmin: boolean;
  payed: number;
  refunded: number;
  checkedIn: boolean;
  checkInStatus: CheckInStatus;
  goTabId?: string;
  startTimer?: Date;
  token?: string;
  squareInvoiceId?: string;
  squareOrderId?: string;
  bookedStatus?: BookedStatus;
  markAsPaidSource?: string;
  managersCompensation?: number;
  paymentMethods?: string;
  reminderTextSent?: boolean;
  stripeInvoiceId?: string;
  addonsByGuestsCount?: number;
  isSyncProblem?: boolean;
  discounts: string;
  reservationActivities?: ReservationActivityDto[];
  activitiesPrice: number;
  inRangePin?: string;
}

export interface ReservationWithTransactionsSettingsDto
  extends ReservationSettingsDto {
  transactions: TransactionSettingDto[];
}

export interface HostReservationSettingsDto extends ReservationSettingsDto {
  venueImage: string;
  secondaryVenueImages: string[];
  packageImage: string;
  userReservationsCount: number;
}

export interface SimpleReservationDto {
  id: string;
  resNumber: string;
  startSlot: number;
  duration: number;
  guestCount: number;
  resources: ReservationResourceDto[];
}

export interface SetReservationCheckInStatusDto {
  id: string;
  status: CheckInStatus;
  isExtendOnComplete?: boolean;
}

export class ReservationCrossSettingsDto {
  id: string;
  startSlot: number;
  duration: number;
  preBufferTime: number;
  bufferTime: number;
  resources: ReservationResourceIdSettingsDto[];
}
export class ReservationResourceIdSettingsDto {
  resourceId: string;
}

export class ExtendTimeReservationSettingsDto {
  reservationId: string;
  newDuration: number;
  chargeAmount?: number
}
export class CompleteExtendTimeReservationSettingsDto {
  reservationId: string;
}