import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import auth from "./auth-reducer";
import venues from "./venues";
import reservation from "./reservation";
import settings from "./settings";
import reports from "./reports";
import promotions from "./promotions";
import tenants from "./tenants";
import uiState from "./ui-reducer";
import packages from "./packages";
import superAdminReports from "./superAdminReports";
import addons from './addons';
import resources from './resources';
import resourceRelations from './resource-relations';
import configs from './configs';
import partners from './partners';
import discounts from './discounts';
import membership from './membership';
import questions from './questions';
import addonResourceRelations from "./addon-resource-relations";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    venues,
    reservation,
    settings,
    reports,
    promotions,
    tenants,
    uiState,
    packages,
    superAdminReports,
    addons,
    resources,
    resourceRelations,
    configs,
    partners,
    discounts,
    membership,
    questions,
    addonResourceRelations
  } as any);

export default rootReducer;
