import { PackageDto } from '../dto/package.dto';
import { PackageNameDto } from '../dto/packageName.dto';
import { CurrencyType, UserType } from '../entities/enums';
import { ReservationDto } from '../../../server/src/dto/reservation.dto';

export const slotToTime = (
  slot: number,
  timeSlotDuration: number,
  twelveHourClockFormat: boolean,
  timeShifting?: number,
) => {
  const timeSlotShifting =
    timeShifting && !isNaN(+timeShifting) ? +timeShifting : 0;
  const minutesShifting = Math.floor(timeSlotShifting % 60);
  const slotsInHour = 60 / timeSlotDuration;
  const hour = Math.floor((slot * timeSlotDuration + timeSlotShifting) / 60);
  const minutes = Math.floor(
    (timeSlotDuration * (slot % slotsInHour) + minutesShifting) % 60,
  );
  return createTimeText(hour, minutes, twelveHourClockFormat);
};

export const createTimeText = (
  hour: number,
  minutes: number,
  twelveHourClockFormat: boolean,
) => {
  const parseMinutes = String(minutes || 0).padStart(2, '0');
  if (twelveHourClockFormat) {
    if (hour >= 24) {
      if (hour == 24) {
        return `12:${parseMinutes || '00'} AM`;
      }
      return `${hour - 24}:${parseMinutes || '00'} AM`;
    }
    if (hour >= 12) {
      if (hour == 12) {
        return `12:${parseMinutes || '00'} PM`;
      }
      return `${hour - 12}:${parseMinutes || '00'} PM`;
    }
    if (hour == 0) {
      return `12:${parseMinutes || '00'} AM`;
    }
    return `${hour}:${parseMinutes || '00'} AM`;
  } else {
    if (hour >= 24) {
      if (hour == 24) {
        return `00:${parseMinutes || '00'}`;
      }
      return `${hour - 24}:${parseMinutes || '00'}`;
    }
    return `${hour}:${parseMinutes || '00'}`;
  }
};

export const composeStartReservationDateTime = ({
  date,
  startSlot,
  timeSlotDuration,
  timeSlotShifting = 0,
}: {
  date: string;
  startSlot: number;
  timeSlotDuration: number;
  timeSlotShifting?: number;
}): string => {
  const time = slotToTime(
    +startSlot,
    +timeSlotDuration,
    true,
    +timeSlotShifting,
  );
  return `${date} ${time}`;
};

export const formatPhone = (phone?: string) => {
  if (phone == '(___) ___-____') {
    return '';
  }
  if (!phone || phone.length !== 10) {
    return phone;
  }
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
};

export const clearPhoneNumber = (phone?: string) => {
  if (!phone) {
    return '';
  }
  return phone
    .replace('+1', '')
    .replace('+', '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/ /g, '');
};

export function normalizeUSPhoneNumber(phone: string): string {
  const normalizedPhone = phone
    .replace('+1', '')
    .replace('+', '')
    .replace(/^1/, '') // number that starts from "1 ". Example "1 (555) 222 3333"
    .replace(/\D/g, ''); // remove not digital characters

  return normalizedPhone.length <= 10
    ? normalizedPhone
    : normalizedPhone.substring(normalizedPhone.length - 10);
}

export const formatInternationalPhone = (phone?: string) => {
  if (!phone || phone == '(___) ___-____') {
    return '';
  }
  const cleanPhone = clearPhoneNumber(phone);
  if (cleanPhone.length === 10) {
    return `1${cleanPhone}`;
  }
  return cleanPhone;
};

export const formatOccasions = (occasions?: string) => {
  const result: { [key: string]: number } = {};
  if (!occasions) {
    return result;
  }
  for (let o of occasions.split(';')) {
    const [key, ts] = o.split(':');
    result[key] = +ts;
  }
  return result;
};

export const formatDuration = (
  duration: number,
  timeSlotDuration?: number,
): string => {
  const slotDuration = timeSlotDuration ? +timeSlotDuration : 30;
  const d = duration * slotDuration;
  const hours = Math.floor(d / 60);
  const minutes = d % 60;
  if (hours === 0) {
    return `${minutes} Min.`;
  } else {
    return `${hours}${minutes > 0 ? `:${minutes}` : ''} ${hours === 1 ? 'Hour' : 'Hours'
      }`;
  }
};

export const formatDurationTime = (
  duration: number,
  timeSlotDuration?: number,
): string => {
  const slotDuration = timeSlotDuration ? +timeSlotDuration : 30;
  const d = (duration * slotDuration) / 60;
  return `${d}`;
};

export const formatDurationRange = (
  durationRange: number[],
  timeSlotDuration?: number,
): string => {
  const defaultTimeSlotDuration = timeSlotDuration ? +timeSlotDuration : 30;

  const convertedTimes = durationRange.map(
    d => (d * defaultTimeSlotDuration) / 60,
  );
  const isHourly = convertedTimes.every(d => d >= 1);
  const isMinutely = convertedTimes.every(d => d < 1);

  if (isHourly || isMinutely) {
    const text = formatDuration(
      durationRange[0],
      defaultTimeSlotDuration,
    ).split(' ')[0];
    return `${text} - ${formatDuration(
      durationRange[1],
      defaultTimeSlotDuration,
    )}`;
  }

  return `${formatDuration(
    durationRange[0],
    defaultTimeSlotDuration,
  )} - ${formatDuration(durationRange[1], defaultTimeSlotDuration)}`;
};

export const transformCustomFields = (customFields?: string) => {
  const result: { [key: string]: string } = {};
  if (!customFields) {
    return result;
  }

  for (let f of customFields.split(';')) {
    const [key, value] = f.split(/:(.*)/s);
    result[key] = value;
  }
  return result;
};

export const formatPrice = (
  price: number,
  currency: CurrencyType,
  isRound: boolean = false,
) => {
  let parsedPrice = '';
  const isNegativeSymbol = price < 0;
  parsedPrice = `${currencyFormatterForPrice(Math.abs(price))}`.replace(
    '$',
    '',
  );
  switch (currency) {
    case CurrencyType.USD:
      return `${isNegativeSymbol ? '-' : ''}$${parsedPrice}`;
    case CurrencyType.CAD:
      return `${isNegativeSymbol ? '-' : ''}$${parsedPrice} CAD`;
    case CurrencyType.NZD:
      return `${isNegativeSymbol ? '-' : ''}$${parsedPrice} NZD`;
    case CurrencyType.GBP:
      return `${isNegativeSymbol ? '-' : ''}£${parsedPrice}`;
    case CurrencyType.EUR:
      return `${isNegativeSymbol ? '-' : ''}€${parsedPrice}`;
    case CurrencyType.AUD:
      return `${isNegativeSymbol ? '-' : ''}$${parsedPrice} AUD`;
    default:
      return `${isNegativeSymbol ? '-' : ''}$${parsedPrice}`;
  }
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

export const currencyFormatterForBigPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;

export const currencyFormatterForPrice = (value: number) => {
  let updatedValue = value;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formattedValue = formatter.format(updatedValue);
  return formattedValue;
};

export const getPackageAssignedDuration = (
  selectedPackage: {
    assignDuration: string;
    assignDurationForAdmin?: string;
  },
  guests: number,
  isAdmin?: boolean,
): number => {
  if (guests && selectedPackage) {
    const assignDuration =
      isAdmin && selectedPackage?.assignDurationForAdmin
        ? selectedPackage.assignDurationForAdmin
        : selectedPackage.assignDuration;
    const durationSlotsString = assignDuration
      .split(';')
      .map((item, index) => ({
        id: index,
        guests: item.split(':')[0] || '',
        duration: item.split(':')[1] || '',
      }));
    const sortGuestArray = durationSlotsString.sort((a, b) =>
      a.guests < b.guests ? 1 : -1,
    );
    const guestsArray = durationSlotsString.map(item => item.guests);
    if (guestsArray.includes(guests.toString())) {
      const durationSlot = sortGuestArray.find(item => +item.guests === guests);
      return durationSlot
        ? +durationSlot?.duration
        : +durationSlotsString[0].duration;
    }
    const durationSlot = sortGuestArray.find(item => +item.guests <= guests);
    return durationSlot
      ? +durationSlot?.duration
      : +durationSlotsString[0].duration;
  }
  return 1;
};

export const getPackageDuration = ({
  selectedPackage,
  reservationDuration,
  isAdmin,
  guests,
}: {
  selectedPackage: {
    enableDurationChoice: boolean;
    duration: number;
    enableAssignDuration: boolean;
    assignDuration: string;
    assignDurationForAdmin?: string;
    durationInSlotsForAdmin?: string;
  },
  reservationDuration: number;
  isAdmin: boolean;
  guests: number;
}): number => {
  let duration = reservationDuration;
  if (!selectedPackage.enableDurationChoice) {
    duration = selectedPackage.duration;
  }
  if (selectedPackage?.enableAssignDuration) {
    duration = getPackageAssignedDuration(selectedPackage, guests, isAdmin);
  }
  if (isAdmin && selectedPackage?.durationInSlotsForAdmin) {
    const adminDurations = selectedPackage.durationInSlotsForAdmin
      .split(',')
      .map(item => +item);
    if (adminDurations.includes(reservationDuration)) {
      duration = reservationDuration;
    } else {
      duration = adminDurations[0];
    }
  }

  return duration;
};

export const getPackageLanes = ({
  selectedPackage,
  reservationLanes,
  isAdmin,
  guests,
}: {
  selectedPackage: {
    countLanesByGuest: boolean;
    maxGuestsPerLane?: number;
    maxGuestsPerLaneForAdmin?: number;
    numberOfLanes: number;
  };
  reservationLanes?: number;
  isAdmin: boolean;
  guests: number;
}): number => {
  if (selectedPackage.countLanesByGuest && selectedPackage.maxGuestsPerLane) {
    let lanes = Math.ceil(guests / selectedPackage.maxGuestsPerLane);
    if (isAdmin && selectedPackage?.maxGuestsPerLaneForAdmin) {
      lanes = Math.ceil(guests / selectedPackage.maxGuestsPerLaneForAdmin);
    }
    return lanes;
  }
  if (!!reservationLanes) {
    return reservationLanes;
  }
  return selectedPackage.numberOfLanes;
};

export const getTotalPrice = (
  price: any,
  tax: any,
  serviceFee: any,
  addonsPrice: any,
): number => {
  const total =
    Math.round(
      ((price ? parseFloat(price) : 0) +
        (tax ? parseFloat(tax) : 0) +
        (serviceFee ? parseFloat(serviceFee) : 0) +
        (addonsPrice ? parseFloat(addonsPrice) : 0)) *
      100,
    ) / 100;
  return total;
};

export const getAxiosError = (error: any): string => {
  let text = 'unknown error';
  try {
    if (error?.response && error?.config) {
      // Request made but the server responded with an error
      text = `Error response: ${error.config.url}: data: ${error.config.data
        } res: ${error.response.status}:${JSON.stringify(error.response.data)}`;
    } else if (error?.config) {
      // Request made but no response is received from the server.
      text = `Error no response from the server: ${error.config.method} ${error.config.url}, data: ${error.config.data}`;
    } else {
      // Error occurred while setting up the request
      text = `Error: ${JSON.stringify(error?.message || error || 'blank')}`;
    }
  } catch (e) {
    console.log(`failed to parse error`);
  }
  return text;
};

export const getDatesForBooking = (
  isAdmin: boolean,
  daysForBookingForClient: number,
  daysForBookingForAdmin?: number,
): number => {
  let daysForBooking = daysForBookingForClient;
  if (isAdmin && !!daysForBookingForAdmin) {
    daysForBooking = daysForBookingForAdmin;
  }
  return daysForBooking;
};

export const getIsStaffTypes = (type: UserType): boolean => {
  return (
    type === UserType.user ||
    type === UserType.userLead ||
    type === UserType.manager
  );
};

export const getIsVenueUser = (type: UserType) => {
  return type === UserType.user || type === UserType.userLead;
};

export const getIsInvalidUserTenantTypes = (
  type: UserType,
  isLeadPermissions?: boolean,
): boolean => {
  if (!!isLeadPermissions) {
    return (
      type !== UserType.userLead &&
      type !== UserType.manager &&
      type !== UserType.admin &&
      type !== UserType.venueAdmin
    );
  }
  return (
    type !== UserType.user &&
    type !== UserType.userLead &&
    type !== UserType.manager &&
    type !== UserType.admin &&
    type !== UserType.venueAdmin
  );
};

export const getIsInvalidSuperAdminTypes = (type: UserType): boolean => {
  return (
    type !== UserType.superAdmin &&
    type !== UserType.partner &&
    type !== UserType.adminSpoton &&
    type !== UserType.adminGoTab &&
    type !== UserType.adminBowlingMarketing
  );
};

export const getDepositAmountWithCurrency = (
  deposit: number,
  currency: CurrencyType,
) => {
  let currencyInMessage = '';
  let currencySymbol = '$';
  switch (currency) {
    case CurrencyType.CAD:
      currencyInMessage = ' CAD';
      break;
    case CurrencyType.NZD:
      currencyInMessage = ' NZD';
      break;
    case CurrencyType.GBP:
      currencySymbol = '£';
      break;
    case CurrencyType.EUR:
      currencySymbol = '€';
      break;
    case CurrencyType.AUD:
      currencyInMessage = ' AUD';
      break;
    default:
      currencyInMessage = '';
  }
  return `${currencySymbol}${deposit.toFixed(2)}${currencyInMessage}`;
};

export const formatCustomFees = (
  data: string,
  currency: CurrencyType,
): { name: string; value: string }[] => {
  const fees = getCustomFees(data);

  return Object.keys(fees).map((key: string) => ({
    name: key,
    value: formatPrice(fees[key], currency),
  }));
};
export const formatCustomFeesNoCurrency = (
  data: string,
): { name: string; value: number }[] => {
  const fees = getCustomFees(data);


  return Object.keys(fees).map((key: string) => ({
    name: key,
    value: fees[key],
  }));
};

const getCustomFees = (data: string): { [key: string]: number } => {
  if (!data || data.split(';')?.length < 1) {
    return {};
  }
  const customFees = data.split(';').map(fee => fee?.split(':'));
  const fees = customFees.reduce(
    (acc: { [key: string]: number }, [name, amount]) => {
      if (isNaN(+amount) || +amount <= 0) {
        return acc;
      }
      if (acc[name]) {
        return { ...acc, [name]: acc[name] + +amount };
      }
      return { ...acc, [name]: +amount };
    },
    {},
  );

  return fees;
}

export const getReservationOccasion = ({
  customFieldsInformation,
  occasion,
}: {
  customFieldsInformation?: string;
  occasion?: string;
}): string => {
  const allFields = (customFieldsInformation
    ? `${customFieldsInformation.replace(/\"/g, '')}`
    : ''
  ).split(';');
  const occasionField = allFields.find(field => field.includes('occasion:'));
  if (occasionField) {
    return occasionField.split(':')[1];
  }
  return occasion || '';
};

export const getPaymentStatus = (reservation: ReservationDto) => {
  let total =
    Math.round(
      (((Number(reservation.price || 0) + Number(reservation.tax || 0)) * 100) /
        100 +
        Number(reservation.serviceFee || 0) +
        Number(reservation.addonsPrice || 0) -
        Number(reservation.giftCardPaid || 0) +
        Number(reservation.modificationFee || 0) -
        Number(reservation.discountAmount || 0) -
        Number(reservation.managersCompensation || 0)
      ) *
      100,
    ) / 100;

  if (total < 0) {
    total = 0;
  }
  let totalDue = Math.round((+total - +reservation.payed) * 100) / 100;

  if (totalDue < 0) {
    totalDue = 0;
  }
  let paymentStatus = 'Paid';
  if (+reservation.refunded > 0 && totalDue !== 0) {
    paymentStatus = 'Refunded';
  } else if (+reservation.payed === 0 && +totalDue === total && total > 0) {
    paymentStatus = 'Unpaid';
  } else if (totalDue > 0) {
    paymentStatus = 'Partial';
  }

  return paymentStatus;
};
export const getStatusStyles = (reservation: ReservationDto) => {
  switch (getPaymentStatus(reservation)) {
    case 'Paid':
      return {
        statusBackground: '#F0FDF4',
        statusColor: '#1E8544',
      }
    case 'Unpaid':
      return {
        statusBackground: '#FDF2F8',
        statusColor: '#BE185D',
      };
    case 'Partial':
      return {
        statusBackground: '#FEFCE8',
        statusColor: '#854D0E',
      };
    default:
      return {
        statusBackground: '#FDF2F8',
        statusColor: '#BE185D',
      };
  }
}


export const getReservationTotalPrice = (reservation: ReservationDto, isInfo?: boolean): number => {
  if (reservation?.total && !isNaN(Number(reservation.total)) && !isInfo) {
    return Number(reservation.total)
  }
  return Number(reservation.price) + Number(reservation.tax) + Number(reservation.serviceFee) + Number(reservation.addonsPrice);
};
export const totalSumFees = (fees: { name: string, value: string }[]): number => {
  let sum = 0;

  for (const fee of fees) {
    const numericValue = parseFloat(fee.value.replace('$', ''));
    sum += numericValue;
  }

  return sum;
};