import { ResourceRelationsState } from "../store/types";
import { createReducer } from "./reducer-utils";
import { ActionTypeEnum } from "../actions";
import { State } from "../store/types";
import { createSelector } from "reselect";
import { AddonResourceRelationsState } from "../store/types/addon-resource-relations";

const initialState: AddonResourceRelationsState = Object.freeze({
    isLoadingAddonResourceRelations: false,
    error: '',
    addonResourceRelations: [],
})

export default createReducer<AddonResourceRelationsState>(initialState, {
    [ActionTypeEnum.GetAddonResourceRelations]: () => (state) => ({
        ...state,
        isLoadingAddonResourceRelations: true,
        error: '',
        addonResourceRelations: []
    }),
    [ActionTypeEnum.GetAddonResourceRelationsSuccess]: (addonResourceRelations) => (state) => ({
        ...state,
        isLoadingAddonResourceRelations: false,
        addonResourceRelations,
        error: ''
    }),
    [ActionTypeEnum.GetAddonResourceRelationsFailure]: (error) => (state) => ({
        ...state,
        isLoadingAddonResourceRelations: false,
        error,
    }),
    [ActionTypeEnum.UpdateAddonResourceRelations]: () => (state) => ({
        ...state,
        isLoadingAddonResourceRelations: true,
        error: '',
    }),
    [ActionTypeEnum.UpdateAddonResourceRelationsSuccess]: (updatedRelations) => (state) => ({
        ...state,
        isLoadingAddonResourceRelations: false,
        error: '',
        addonResourceRelations: updatedRelations,
    }),
    [ActionTypeEnum.UpdateAddonResourceRelationsFailure]: (error) => (state) => ({
        ...state,
        isLoadingAddonResourceRelations: false,
        error,
    })
});

export const selectAddonResourceRelationsState = (state: State) => state.addonResourceRelations;

export const selectResourceRelations = createSelector(
    selectAddonResourceRelationsState,
    (state) => state.addonResourceRelations
);

export const selectIsLoadingResourceRelations = createSelector(
    selectAddonResourceRelationsState,
    (state) => state.isLoadingAddonResourceRelations
)