import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  IDropdownOption,
  Toggle,
  Checkbox,
} from "office-ui-fabric-react";
import { MentionsInput, Mention } from 'react-mentions';
import { changeTripleSeatPackageStatus, tripleSeatPackageStatusToUIModel, uiModelToTripleSeatPackageStatus, updatePackageAction } from "../../../../../actions/package-action";
import { selectPackage } from "../../../../../reducers/packages";
import { selectVenue } from "../../../../../reducers/venues";
import { State, TripleSeatBlockedStatus, TripleSeatCustomReservationFields, TripleSeatPublishType, TripleSeatAvailabilityType } from "../../../../../store/types";
import { selectReadonly } from "../../../../../reducers/auth-reducer";
import { useViewport } from "../../../../../hooks/responsive";
import FormTextField from "../../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import FormSelectField from "../../../../common/FormSelectField";
import ColorButton from "../../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../../common/components/CancelButton";
import { formatLabel } from "../../../../../utils/formats";
import InputPlusIcon from '../../../../../../../common/assets/input-plus.svg'
import "./editTripleSeatForPackage.scss";

const mentionsInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 16,
    fontWeight: 'normal',
  },
  '&singleLine': {
    width: '100%',
    height: '40px',
    paddingLeft: '8px',
    highlighter: {
      paddingTop: '10px',
      height: '40px',
      outline: 0,
    },
    input: {
      padding: '5px 40px 5px 8px',
      height: '40px',
      border: "1px solid #D5D8E1",
      borderRadius: "6px",
      outline: 0,
    },
  },
  suggestions: {
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    },
    borderRadius: '5px',

    list: {
      backgroundColor: 'white',
      border: '1px solid #D5D8E1',
      fontSize: 16,
      maxHeight: '206px',
      overflow: 'auto',
      borderRadius: '5px',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey'
      }
    },
    item: {
      padding: '5px 5px 5px 15px',
      borderBottom: '1px solid #D5D8E1',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',

      '&focused': {
        backgroundColor: 'rgba(80, 70, 228, 0.04)',
        color: 'rgb(6, 36, 255)'
      },
    },
  },
}

const mentionsStyle = {
  borderRadius: "6px",
  background: 'rgb(243, 242, 241)',
}

const tsStatusList = [
  TripleSeatBlockedStatus.Definite,
  TripleSeatBlockedStatus.Prospect,
  TripleSeatBlockedStatus.Tentative,
  TripleSeatBlockedStatus.Closed,
  TripleSeatBlockedStatus.Lost,
];

const tsStatus = Object.values(TripleSeatBlockedStatus).map((value: string) => ({ key: value, text: formatLabel(value) }));

const tsPublishType = [
  {
    key: TripleSeatPublishType.Event,
    text: "Event",
  },
  {
    key: TripleSeatPublishType.Booking,
    text: "Booking",
  },
];

const tsAvailabilityType = [
  {
    key: TripleSeatAvailabilityType.NumberOfGuests,
    text: "Number of Guests",
  },
  {
    key: TripleSeatAvailabilityType.NumberOfGuaranteedGuests,
    text: "Number of Guaranteed Guests",
  },
  {
    key: TripleSeatAvailabilityType.NumberOfRooms,
    text: "Number of Rooms",
  },
  {
    key: TripleSeatAvailabilityType.CustomField,
    text: "Custom Field",
  },
];

const mapDispatchToProps = {
  updatePackage: updatePackageAction,
};
const mapStateToProps = (state: State) => ({
  currentPackage: selectPackage(state),
  venue: selectVenue(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditTripleSeatForPackage = ({
  updatePackage,
  currentPackage,
  readonly,
  onClose,
  venue,
}: Props) => {
  const { isMobile } = useViewport();
  useEffect(() => {
    setPackageSpecificTripleSeatsIntegration(currentPackage?.packageSpecificTripleSeatsIntegration || false);
    setTripleSeatRoomId(currentPackage?.tripleSeatRoomId);
    setTripleSeatLocationId(currentPackage?.tripleSeatLocationId);
    setTripleSeatAccountId(currentPackage?.tripleSeatAccountId);
    setTripleSeatContactId(currentPackage?.tripleSeatContactId);
    setTripleSeatBlockedStatus(currentPackage?.tripleSeatBlockedStatus || TripleSeatBlockedStatus.Definite);
    setTripleSeatPublishType(currentPackage?.tripleSeatPublishType || TripleSeatPublishType.Event);
    setTripleSeatReadAvailability(!!currentPackage?.tripleSeatReadAvailability);
    setTripleSeatAvailabilityType(currentPackage?.tripleSeatAvailabilityType || TripleSeatAvailabilityType.NumberOfGuests);
    setTripleSeatAvailabilityFieldId(currentPackage?.tripleSeatAvailabilityFieldId || '');
    setTripleSeatStatusesOfPulledEvents(tripleSeatPackageStatusToUIModel(currentPackage?.tripleSeatStatusesOfPulledEvents || ''));
    setTripleSeatEventTypeId(currentPackage?.tripleSeatEventTypeId);
  }, [currentPackage]);

  const [packageSpecificTripleSeatsIntegration, setPackageSpecificTripleSeatsIntegration] = useState(currentPackage?.packageSpecificTripleSeatsIntegration);
  const [tripleSeatRoomId, setTripleSeatRoomId] = useState(currentPackage?.tripleSeatRoomId);
  const [tripleSeatLocationId, setTripleSeatLocationId] = useState(currentPackage?.tripleSeatLocationId);
  const [tripleSeatAccountId, setTripleSeatAccountId] = useState(currentPackage?.tripleSeatAccountId);
  const [tripleSeatContactId, setTripleSeatContactId] = useState(currentPackage?.tripleSeatContactId);
  const [tripleSeatEventTypeId, setTripleSeatEventTypeId] = useState(currentPackage?.tripleSeatEventTypeId);
  const [tripleSeatBlockedStatus, setTripleSeatBlockedStatus] = useState<string>(currentPackage?.tripleSeatBlockedStatus || "");
  const [tripleSeatPublishType, setTripleSeatPublishType] = useState<string>(currentPackage?.tripleSeatPublishType || "");
  const [tripleSeatCustomEventName, setTripleSeatCustomEventName] = useState<string>(currentPackage?.tripleSeatCustomEventName || "");
  const [tripleSeatReadAvailability, setTripleSeatReadAvailability] = useState<boolean>(!!currentPackage?.tripleSeatReadAvailability);
  const [tripleSeatAvailabilityType, setTripleSeatAvailabilityType] = useState<string>(currentPackage?.tripleSeatAvailabilityType || "");
  const [tripleSeatAvailabilityFieldId, setTripleSeatAvailabilityFieldId] = useState<string>(currentPackage?.tripleSeatAvailabilityFieldId || "");
  const [tripleSeatStatusesOfPulledEvents, setTripleSeatStatusesOfPulledEvents] = useState<string[]>(
    tripleSeatPackageStatusToUIModel(currentPackage?.tripleSeatStatusesOfPulledEvents || "")
  );

  const [errorTripleSeatRoomId, setErrorTripleSeatRoomId] = useState("");
  const [errorTripleSeatLocationId, setErrorTripleSeatLocationId] = useState("");
  const [errorTripleSeatAvailability, setErrorTripleSeatAvailability] = useState("");

  const isValidPackage = () => {
    if (packageSpecificTripleSeatsIntegration && !tripleSeatRoomId) {
      setErrorTripleSeatRoomId("This field is required.");
      return false
    }
    if (packageSpecificTripleSeatsIntegration && !tripleSeatLocationId) {
      setErrorTripleSeatLocationId("This field is required.");
      return false
    }
    if (tripleSeatReadAvailability && tripleSeatAvailabilityType === TripleSeatAvailabilityType.CustomField && !tripleSeatAvailabilityFieldId) {
      setErrorTripleSeatAvailability("This field is required.");
      return false
    }
    if (tripleSeatReadAvailability && tripleSeatAvailabilityType === TripleSeatAvailabilityType.NumberOfRooms) {
      if (!tripleSeatAvailabilityFieldId) {
        setErrorTripleSeatAvailability("This field is required.");
        return false
      }
      const roomIds = tripleSeatAvailabilityFieldId.split(',');
      for (let roomId of roomIds) {
        if (isNaN(+roomId) || !roomId) {
          setErrorTripleSeatAvailability("Set valid room id list.");
          return false
        }
      }
    }
    return (
      tripleSeatRoomId &&
      tripleSeatLocationId &&
      tripleSeatBlockedStatus &&
      tripleSeatPublishType &&
      venue
    )
  }

  const getPatch = () => {
    if (!venue) {
      return
    }
    return {
      packageSpecificTripleSeatsIntegration,
      tripleSeatRoomId,
      tripleSeatLocationId,
      tripleSeatAccountId: tripleSeatAccountId || null,
      tripleSeatContactId: tripleSeatContactId || null,
      tripleSeatBlockedStatus,
      tripleSeatPublishType,
      tripleSeatCustomEventName: tripleSeatCustomEventName || null,
      tripleSeatReadAvailability,
      tripleSeatAvailabilityType,
      tripleSeatAvailabilityFieldId,
      tripleSeatStatusesOfPulledEvents: uiModelToTripleSeatPackageStatus(tripleSeatStatusesOfPulledEvents) || "",
      tripleSeatEventTypeId,
    };
  }

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    setErrorTripleSeatRoomId("");
    setErrorTripleSeatLocationId("");
    setErrorTripleSeatAvailability("");

    if (!isValidPackage()) {
      return;
    }

    if (currentPackage) {
      const patch = getPatch();
      updatePackage({ ...currentPackage, ...patch });
    }
    onClose();
  };

  const onChangeTripleSeatAvailabilityType = (availabilityType: string) => {
    setTripleSeatAvailabilityType(availabilityType);
    setTripleSeatAvailabilityFieldId('');
    setErrorTripleSeatAvailability("");
  };

  const eventNameList = Object.keys(TripleSeatCustomReservationFields).map(item => ({ id: item, display: item })).filter(item => item.id !== "PlayerDetails" && item.id !== "Custom");

  const tripleSeatPackageStatusList = tsStatusList.map((status) => {
    const isSelected = tripleSeatStatusesOfPulledEvents.includes(status);
    return (
      <Checkbox
        key={status}
        className="ts-status"
        label={status.charAt(0) + status.slice(1).toLowerCase()}
        defaultChecked={isSelected}
        onChange={(_: any, checked?: boolean) =>
          setTripleSeatStatusesOfPulledEvents(changeTripleSeatPackageStatus(status, tripleSeatStatusesOfPulledEvents, checked))
        }
        disabled={readonly || !tripleSeatReadAvailability}
      />
    );
  });
  const [rotateAngle, setRotateAngle] = useState<number>(0);

  const mentionsRef = useRef<any>(null)
  const handleClickCustom = () => {
    setRotateAngle((prevAngle) => prevAngle + 180);

    setTimeout(() => {
      setTripleSeatCustomEventName(`${tripleSeatCustomEventName}@`)
      if (mentionsRef.current) {
        mentionsRef.current.focus()
      }
    }, 500)
  }


  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-tripleseat-for-package"
    >
      <div className="title h4">
        Custom Tripleseat Settings
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="tripleseat" onSubmit={onUpdate}>
        <div className="edit-tripleseat-for-package-fields">
          <div className="row">
            <Toggle
              label="Specific Tripleseats Integration"
              checked={packageSpecificTripleSeatsIntegration}
              onChange={(_: any, value?: boolean) => setPackageSpecificTripleSeatsIntegration(value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Location ID"
              type="number"
              className="integrations-field"
              value={tripleSeatLocationId?.toString() || ""}
              errorMessage={errorTripleSeatLocationId}
              onChange={(_: any, text?: string) =>
                setTripleSeatLocationId(text ? parseInt(text) : null)
              }
              autoComplete="off"
              required={!!packageSpecificTripleSeatsIntegration}
              disabled={readonly}
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Room ID"
              type="number"
              className="integrations-field"
              value={tripleSeatRoomId?.toString() || ""}
              errorMessage={errorTripleSeatRoomId}
              onChange={(_: any, text?: string) =>
                setTripleSeatRoomId(text ? parseInt(text) : null)
              }
              autoComplete="off"
              required={!!packageSpecificTripleSeatsIntegration}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Account ID"
              type="number"
              className="integrations-field"
              value={tripleSeatAccountId?.toString() || ""}
              onChange={(_: any, text?: string) =>
                setTripleSeatAccountId(text ? parseInt(text) : null)
              }
              autoComplete="off"
              disabled={readonly}
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Contact ID"
              type="number"
              className="integrations-field"
              value={tripleSeatContactId?.toString() || ""}
              onChange={(_: any, text?: string) =>
                setTripleSeatContactId(text ? parseInt(text) : null)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Event Type ID"
              type="number"
              className="integrations-field"
              value={tripleSeatEventTypeId?.toString() || ""}
              onChange={(_: any, text?: string) =>
                setTripleSeatEventTypeId(text ? parseInt(text) : null)
              }
              autoComplete="off"
              disabled={readonly}
            />
            {!isMobile && (<div className="delimiter" />)}

            <FormSelectField
              label="Status"
              options={tsStatus}
              selectedKey={tripleSeatBlockedStatus}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setTripleSeatBlockedStatus(item?.key as string)}
              required={!!packageSpecificTripleSeatsIntegration}
              disabled={readonly}
            />

          </div>
          <div className="row">
            <FormSelectField
              label="Publish Type"
              options={tsPublishType}
              selectedKey={tripleSeatPublishType}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setTripleSeatPublishType(item?.key as string)}
              required={!!packageSpecificTripleSeatsIntegration}
              disabled={true}
            />
          </div>
          <div className="event-title-row">
            <div className="field-title subtitle2">Title</div>

            <div className="mentions-input">
              <MentionsInput
                singleLine
                value={tripleSeatCustomEventName}
                onChange={(_, value?: string) => setTripleSeatCustomEventName(value || "")}
                style={mentionsInputStyle}
                placeholder={"Event Title"}
                a11ySuggestionsListLabel={"Suggested mentions"}
                disabled={readonly}
                inputRef={mentionsRef}
              >
                <Mention data={eventNameList} style={mentionsStyle} markup={'@[__id__]'} trigger='@' appendSpaceOnAdd renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <>
                    {highlightedDisplay}
                    {/* <ArrowForwardIcon /> */}
                  </>
                )}
                />
              </MentionsInput>
              <span className="input-plus" style={{ width: '20px', height: '20px', transform: `rotate(${rotateAngle}deg)`, transition: 'transform 0.6s ease-out' }} onClick={handleClickCustom}>
                <img src={InputPlusIcon} />
              </span>
            </div>

          </div>
          <div className="row">
            <Toggle
              label="Read availability from Tripleseat"
              checked={tripleSeatReadAvailability}
              onChange={(_: any, value?: boolean) => setTripleSeatReadAvailability(!!value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
              label="Availability Type"
              options={tsAvailabilityType}
              selectedKey={tripleSeatAvailabilityType}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => onChangeTripleSeatAvailabilityType(item?.key as string)}
              required={!!tripleSeatReadAvailability}
              disabled={readonly || !tripleSeatReadAvailability}
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label={tripleSeatAvailabilityType === TripleSeatAvailabilityType.NumberOfRooms ? "Room IDs" : "Custom Field ID"}
              className="integrations-field"
              value={tripleSeatAvailabilityFieldId || ""}
              errorMessage={errorTripleSeatAvailability}
              onChange={(_: any, text: string = '') =>
                setTripleSeatAvailabilityFieldId(text.trim())
              }
              autoComplete="off"
              required={tripleSeatAvailabilityType === TripleSeatAvailabilityType.CustomField || tripleSeatAvailabilityType === TripleSeatAvailabilityType.NumberOfRooms}
              disabled={
                readonly ||
                !tripleSeatReadAvailability || (
                  tripleSeatAvailabilityType != TripleSeatAvailabilityType.CustomField &&
                  tripleSeatAvailabilityType != TripleSeatAvailabilityType.NumberOfRooms
                )}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <div className="select-group-status">
              <div className="status-field group-title">Statuses</div>
              <div className="status-field">{tripleSeatPackageStatusList}</div>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal >
  );
};

export default connector(EditTripleSeatForPackage);
