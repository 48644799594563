export class ConfigsDto {
  aboutGolfUrl: string;
  aboutGolfApiVersion: string;
  aboutGolfApiKey: string;
  aboutGolfSubscriptionKey: string;
  goTabApiAccessId: string;
  goTabApiAccessSecret: string;
  mchClientId: string;
  mchClientSecret: string;
  squareClientId: string;
  squareClientSecret: string;
  squareAccessToken: string;
  stripePublicKey: string;
  stripeSecretKey: string;
  stripeWebhookSecret: string;
  sandboxStripePublicKey: string;
  sandboxStripeSecretKey: string;
  stripeClientId: string;
  sandboxStripeClientId: string;
  atomicGolfUrl: string;
  atomicGolfClientId: string;
  atomicGolfClientSecret: string;
  atomicGolfTenantId: string;
  atomicGolfCustomFieldForCheckIn: string;
  inRangeAPIKey: string;
}
