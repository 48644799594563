import React, { useState, useEffect } from "react";
import { IDropdownOption, Modal } from "office-ui-fabric-react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "../../../../store/types";
import CancelButton from "../../../../../../common/components/CancelButton";
import { useViewport } from "../../../../hooks/responsive";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import { selectAboutGolfSims, selectCurrentResource } from "../../../../reducers/resources";
import { selectGoTabSpots, selectVenue } from "../../../../reducers/venues";
import {
  addResourceAction,
  getAboutGolfSimListAction,
  updateResourceAction,
} from "../../../../actions/resources-action";
import FormSelectField from "../../../common/FormSelectField";
import "./index.scss";
import { getGoTabProductsAndSpotsForVenueAction } from "../../../../actions/venue-actions";

const mapStateToProps = (state: State) => ({
  currentResource: selectCurrentResource(state),
  currentVenue: selectVenue(state),
  aboutGolfSims: selectAboutGolfSims(state),
  goTabSpots: selectGoTabSpots(state),
});

const mapDispatchToProps = {
  addResource: addResourceAction,
  updateResource: updateResourceAction,
  getAboutGolfSimList: getAboutGolfSimListAction,
  getGoTabProductsAndSpotsForVenue: getGoTabProductsAndSpotsForVenueAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
  resourceCategoryId?: string;
};

enum ResourceTab {
  Info = "Info",
  Mapping = "Mapping",
}

// rowOptions from 1 to 5
const rowOptions = Array.from({ length: 5 }, (_, i) => ({ key: i + 1, text: (i + 1).toString() }));

const EditResource = ({
  onClose,
  currentResource,
  resourceCategoryId,
  currentVenue,
  aboutGolfSims,
  addResource,
  updateResource,
  getAboutGolfSimList,
  getGoTabProductsAndSpotsForVenue,
  goTabSpots
}: Props) => {
  useEffect(() => {
    if (aboutGolfSims.length > 0) {
      const simsList = aboutGolfSims.map(item => ({ key: item.id, text: item.name }));
      setAboutGolfSimList(simsList);
    }
  }, [aboutGolfSims]);
  useEffect(() => {
    if (goTabSpots.length > 0) {
      const spotList = goTabSpots.map((item) => ({
        key: item.spotUuid,
        text: item.name,
      }));
      setGoTabSpotList(spotList);
    }
  }, [goTabSpots]);

  useEffect(() => {
    if (!currentVenue?.enableGoTabIntegration || !currentVenue?.goTabLocation) {
      return;
    } else if (
      (goTabSpots.length === 0 && currentVenue?.goTabSpotUuid)
    ) {
      getGoTabProductsAndSpotsForVenue(currentVenue.goTabLocation);
    }

  }, [currentVenue]);


  const { isMobile } = useViewport();
  const [activeTab, setActiveTab] = useState(ResourceTab.Info);
  const [aboutGolfSimList, setAboutGolfSimList] = React.useState<IDropdownOption[]>(() => {
    const selectedOption = currentResource?.aboutGolfSimId ? currentResource?.aboutGolfSimId.split(':') : [];
    if (selectedOption.length > 0) {
      const name = selectedOption.slice(1).join(':'); //if sim name include ':'
      return [{ key: selectedOption[0], text: name }]
    }
    return [];
  });
  const [values, setValues] = useState({
    name: currentResource?.name || "",
    abbreviation: currentResource?.abbreviation || "",
    priority: currentResource?.priority || 0,
    steltronicNumber: currentResource?.steltronicNumber || 0,
    tripleSeatRoomId: currentResource?.tripleSeatRoomId || 0,
    tripleSeatRoomPath: currentResource?.tripleSeatRoomPath || "",
    aboutGolfSimId: currentResource?.aboutGolfSimId || "",
    atomicGolfBayId: currentResource?.atomicGolfBayId || "",
    goTabSpotUuid: currentResource?.goTabSpotUuid || "",
    inRangeBayNumber: currentResource?.inRangeBayNumber || "",
    row: currentResource?.row || 1,
  });
  const [goTabSpotList, setGoTabSpotList] = useState<IDropdownOption[]>([]);

  const [errorMessages, setErrorMessages] = useState<Record<string, string>>(
    {}
  );

  const setValue = (key: keyof typeof values, value: any) => {
    setValues({ ...values, [key]: value });
  };

  const getErrorMessages = () => {
    const errors: Record<string, string> = {};

    if (!values.name) {
      errors.name = "Please input name";
    }

    if (!values.abbreviation) {
      errors.abbreviation = "Please input abbreviation";
    }

    if (!values.priority) {
      errors.priority = "Please input category priority";
    } else if (isNaN(+values.priority)) {
      errors.priority = "Please input a valid number";
    }

    return errors;
  };

  const onGetAboutGolfSimList = () => {
    if (!!currentVenue?.aboutGolfFacilityId) {
      getAboutGolfSimList(currentVenue.aboutGolfFacilityId);
    }
  }

  const setAboutGolfSim = (sim?: IDropdownOption) => {
    if (sim) {
      const newSim = `${sim.key}:${sim.text}`;
      setValue("aboutGolfSimId", newSim);
    }
  }

  const onGetGoTabProductAndSpotList = () => {
    if (!!currentVenue?.goTabLocation) {
      getGoTabProductsAndSpotsForVenue(currentVenue.goTabLocation);
    }
  };
  const onChangeGoTabSpot = (
    _: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) => {
    setValue("goTabSpotUuid", item?.key);
  }


  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const errorMessages = getErrorMessages();
    setErrorMessages(errorMessages);

    if (Object.values(errorMessages).length > 0) return;

    if (currentResource) {
      updateResource({
        ...currentResource,
        name: values.name,
        abbreviation: values.abbreviation,
        priority: values.priority,
        steltronicNumber: values.steltronicNumber || 0,
        tripleSeatRoomId: values.tripleSeatRoomId || 0,
        tripleSeatRoomPath: values.tripleSeatRoomPath || "",
        aboutGolfSimId: values.aboutGolfSimId || "",
        atomicGolfBayId: values.atomicGolfBayId || "",
        goTabSpotUuid: values.goTabSpotUuid || "",
        inRangeBayNumber: values.inRangeBayNumber || "",
        row: values.row || 1,
      });
    } else {
      addResource({
        name: values.name,
        abbreviation: values.abbreviation,
        resourceCategoryId: resourceCategoryId || "",
        priority: values.priority,
        steltronicNumber: values.steltronicNumber || 0,
        tripleSeatRoomId: values.tripleSeatRoomId || 0,
        tripleSeatRoomPath: values.tripleSeatRoomPath || "",
        aboutGolfSimId: values.aboutGolfSimId || "",
        atomicGolfBayId: values.atomicGolfBayId || "",
        goTabSpotUuid: values.goTabSpotUuid || "",
        inRangeBayNumber: values.inRangeBayNumber || "",
        row: values.row || 1,
      });
      setValues({
        name: "",
        abbreviation: "0",
        priority: 0,
        steltronicNumber: 0,
        tripleSeatRoomId: 0,
        tripleSeatRoomPath: "",
        aboutGolfSimId: "",
        atomicGolfBayId: "",
        goTabSpotUuid: "",
        inRangeBayNumber: "",
        row: 1,
      });
    }

    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-resource"
    >
      <div className="title h4">
        {currentResource?.id ? "Edit" : "Create New"} Resource
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="resource" onSubmit={onSubmit}>
        <div className="edit-resource-toggle">
          <div
            className={`edit-resource-toggle-label body2 ${activeTab === ResourceTab.Info ? "active" : ""
              }`}
            onClick={() => setActiveTab(ResourceTab.Info)}
          >
            Info
          </div>
          <div
            className={`edit-resource-toggle-label body2 ${activeTab === ResourceTab.Mapping ? "active" : ""
              }`}
            onClick={() => setActiveTab(ResourceTab.Mapping)}
          >
            Mapping
          </div>
        </div>
        {activeTab === ResourceTab.Info && (
          <>
            {" "}
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormTextField
                label="Resource Name"
                value={values.name}
                onChange={(_: any, text?: string) => setValue("name", text)}
                autoComplete="off"
                className="long-field"
                errorMessage={errorMessages.name}
              />
              {!isMobile && <div className="delimiter" />}
              <FormTextField
                label="Abbreviation"
                value={String(values.abbreviation)}
                onChange={(_: any, text?: string) =>
                  setValue("abbreviation", text)
                }
                autoComplete="off"
                className="short-field"
                errorMessage={errorMessages.abbreviation}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormTextField
                label="Priority"
                value={String(values.priority)}
                onChange={(_: any, text?: string) => setValue("priority", text)}
                autoComplete="off"
                className="long-field"
                errorMessage={errorMessages.priority}
              />
              {!isMobile && <div className="delimiter" />}
              <FormSelectField
                label="Row"
                className="short-field"
                options={rowOptions}
                selectedKey={values.row}
                onChange={(
                  _: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption
                ) => setValue("row", item?.key)}
              />
            </div>
          </>
        )}
        {activeTab === ResourceTab.Mapping && (
          <>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormTextField
                label="Steltronic Lane ID"
                value={String(values.steltronicNumber)}
                type="number"
                min={0}
                onChange={(_: any, text?: string) =>
                  setValue("steltronicNumber", text)
                }
                autoComplete="off"
                className="short-field"
                errorMessage={errorMessages.steltronicNumber}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormTextField
                label="TripleSeat Room ID"
                value={String(values.tripleSeatRoomId)}
                type="number"
                min={0}
                onChange={(_: any, text?: string) =>
                  setValue("tripleSeatRoomId", text)
                }
                autoComplete="off"
                className="short-field"
                errorMessage={errorMessages.tripleSeatRoomId}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormTextField
                label="TripleSeat Room Path"
                value={values.tripleSeatRoomPath}
                onChange={(_: any, text?: string) => setValue("tripleSeatRoomPath", text)}
                autoComplete="off"
                className="short-field"
                errorMessage={errorMessages.tripleSeatRoomPath}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <ColorButton
                className="about-golf-button"
                onClick={() => onGetAboutGolfSimList()}
                disabled={!currentVenue?.aboutGolfFacilityId || !currentVenue?.enableAboutGolfIntegration}
              >
                Get About Golf Sim List
              </ColorButton>
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormSelectField
                label="About Golf Sim ID"
                className="short-field"
                options={aboutGolfSimList}
                selectedKey={values.aboutGolfSimId ? values.aboutGolfSimId.split(':')[0] : ""}
                onChange={(
                  _: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption
                ) => setAboutGolfSim(item)}
                disabled={aboutGolfSimList.length === 0}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormTextField
                label="ATG Bay ID"
                value={values.atomicGolfBayId}
                onChange={(_: any, text?: string) => setValue("atomicGolfBayId", text)}
                autoComplete="off"
                className="short-field"
                errorMessage={errorMessages.atomicGolfBayId}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormTextField
                label="In Range Bay Number"
                value={values.inRangeBayNumber}
                onChange={(_: any, text?: string) => setValue("inRangeBayNumber", text)}
                autoComplete="off"
                className="short-field"
                errorMessage={errorMessages.inRangeBayNumber}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <ColorButton
                className="go-tab-button"
                onClick={onGetGoTabProductAndSpotList}
                disabled={!currentVenue?.goTabLocation}
              >
                Get GoTab Spot List
              </ColorButton>
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormSelectField
                label="GoTab Spot"
                className="field"
                options={goTabSpotList}
                selectedKey={values.goTabSpotUuid}
                onChange={onChangeGoTabSpot}
                disabled={goTabSpotList.length === 0}
              />
            </div>
          </>
        )}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit">Save</ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditResource);
