import { ActionTypeEnum, AppThunk } from "./index";
import { getWithAuth, postWithAuth } from "./api";
import { addErrorAction } from "./auth-actions";
import { AddonResourceCategoriesRelationDto, ResourceCategoriesPackageVenueRelationDto } from "../store/types";

const PACKAGES_URL = "/api/package-setting";
const VENUES_URL = "/api/venue-setting";
const ADDONS_URL = "/api/addons-setting";

export const getResourceRelationsForVenueAction = (venueId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetResourceRelations });

    const response = await getWithAuth(`${VENUES_URL}/resource-category-relations/${venueId}`);

    dispatch({
      type: ActionTypeEnum.GetResourceRelationsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get resource relations error", e);
    dispatch({
      type: ActionTypeEnum.GetResourceRelationsFailure,
      payload: "GetResourceRelations failure",
    });
    dispatch(addErrorAction("Get resource relations error"));
  }
}

export const updateResourceRelationsForVenueAction = (
  {
    venueId,
    relations
  }: {
    venueId: string,
    relations: ResourceCategoriesPackageVenueRelationDto[]
  }
): AppThunk => async (
  dispatch
) => {
    try {
      dispatch({ type: ActionTypeEnum.UpdateResourceRelations });

      const response = await postWithAuth(`${VENUES_URL}/resource-category-relations/${venueId}`, relations);

      dispatch({
        type: ActionTypeEnum.UpdateResourceRelationsSuccess,
        payload: response.data,
      });
    } catch (e) {
      console.log("update resource relations error", e);
      dispatch({
        type: ActionTypeEnum.UpdateResourceRelationsFailure,
        payload: "UpdateResourceRelations failure",
      });
      dispatch(addErrorAction("Update resource relations error"));
    }
  }

export const getResourceRelationsForPackageAction = (
  {
    packageId,
    venueId
  }: {
    packageId: string,
    venueId: string
  }
): AppThunk => async (
  dispatch
) => {
    try {
      dispatch({ type: ActionTypeEnum.GetResourceRelations });

      const response = await getWithAuth(`${PACKAGES_URL}/resource-category-relations/${packageId}/${venueId}`);

      dispatch({
        type: ActionTypeEnum.GetResourceRelationsSuccess,
        payload: response.data,
      });
    } catch (e) {
      console.log("get resource relations error", e);
      dispatch({
        type: ActionTypeEnum.GetResourceRelationsFailure,
        payload: "GetResourceRelations failure",
      });
      dispatch(addErrorAction("Get resource relations error"));
    }
  }

export const updateResourceRelationsForPackageAction = (
  {
    packageId,
    venueId,
    relations
  }: {
    packageId: string,
    venueId: string,
    relations: ResourceCategoriesPackageVenueRelationDto[]
  }
): AppThunk => async (
  dispatch
) => {
    try {
      dispatch({ type: ActionTypeEnum.UpdateResourceRelations });

      const response = await postWithAuth(`${PACKAGES_URL}/resource-category-relations/${packageId}/${venueId}`, relations);

      dispatch({
        type: ActionTypeEnum.UpdateResourceRelationsSuccess,
        payload: response.data,
      });
    } catch (e) {
      console.log("update resource relations error", e);
      dispatch({
        type: ActionTypeEnum.UpdateResourceRelationsFailure,
        payload: "UpdateResourceRelations failure",
      });
      dispatch(addErrorAction("Update resource relations error"));
    }
  }

export const getResourceRelationsForAddonAction = (
  {
    addonId
  }: {
    addonId: string
  }
): AppThunk => async (
  dispatch
) => {
    try {
      dispatch({ type: ActionTypeEnum.GetAddonResourceRelations });

      const response = await getWithAuth(`${ADDONS_URL}/resource-category-relations/${addonId}`);
      dispatch({
        type: ActionTypeEnum.GetAddonResourceRelationsSuccess,
        payload: response.data,
      });
    } catch (e) {
      console.log("get resource relations error", e);
      dispatch({
        type: ActionTypeEnum.GetAddonResourceRelationsFailure,
        payload: "GetResourceRelations failure",
      });
      dispatch(addErrorAction("Get resource relations error"));
    }
  }

export const updateResourceRelationsForAddonAction = (
  {
    addonId,
    relations
  }: {
    addonId: string,
    relations: AddonResourceCategoriesRelationDto[]
  }
): AppThunk => async (
  dispatch
) => {
    try {
      dispatch({ type: ActionTypeEnum.UpdateAddonResourceRelations });

      const response = await postWithAuth(`${ADDONS_URL}/resource-category-relations/${addonId}`, relations);

      dispatch({
        type: ActionTypeEnum.UpdateAddonResourceRelationsSuccess,
        payload: response.data,
      });
    } catch (e) {
      console.log("update resource relations error", e);
      dispatch({
        type: ActionTypeEnum.UpdateAddonResourceRelationsFailure,
        payload: "UpdateAddonResourceRelations failure",
      });
      dispatch(addErrorAction("Update resource relations error"));
    }
  }