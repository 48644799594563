import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal, Toggle } from "office-ui-fabric-react";
import {
  updateVenueAction,
  setVenueErrorAction,
} from "../../../../actions/venue-actions";
import {
  selectVenue,
  selectIsLoadingVenue,
  selectVenueError,
} from "../../../../reducers/venues";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import FormTextField from "../../../../../../common/components/FormTextField";
import FormHtmlTextField from "../../../FormHtmlTextField/FormHtmlTextField";

import "./editConfirmation.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setVenueError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  error: selectVenueError(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditConfirmation = ({
  updateVenue,
  venue,
  readonly,
  isLoadingVenue,
  error,
  onClose,
  setVenueError,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setVenueError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  const [boldTextInAnEmail, setBoldTextInAnEmail] = useState(
    venue?.boldTextInAnEmail
  );
  const [section1Title, setSection1Title] = useState(venue?.section1Title);
  const [section2Title, setSection2Title] = useState(venue?.section2Title);
  const [section2Paragraph, setSection2Paragraph] = useState(
    venue?.section2Paragraph
  );
  const [delineationTextInAnEmail, setDelineationTextInAnEmail] = useState(
    venue?.delineationTextInAnEmail
  );

  const [eventEmailTitle, setEventEmailTitle] = useState(
    venue?.eventEmailTitle
  );
  const [eventEmailCopy, setEventEmailCopy] = useState(venue?.eventEmailCopy);

  const [thankYouEmailEnable, setThankYouEmailEnable] = useState(
    venue?.thankYouEmailEnable
  );
  const [eventInquiryEmailEnable, setEventInquryEmailEnable] = useState(
    venue?.eventInquiryEmailEnable
  );
  const [thankYouEmailMinutes, setThankYouEmailMinutes] = useState(
    venue?.thankYouEmailMinutes
  );
  const [thankYouEmailSubject, setThankYouEmailSubject] = useState(
    venue?.thankYouEmailSubject
  );
  const [thankYouEmailHeader, setThankYouEmailHeader] = useState(
    venue?.thankYouEmailHeader
  );
  const [thankYouEmailBody, setThankYouEmailBody] = useState(
    venue?.thankYouEmailBody
  );
  const [reminderEmailEnable, setReminderEmailEnable] = useState(
    venue?.reminderEmailEnable
  );
  const [reminderEmailDelayHours, setReminderEmailDelayHours] = useState(
    venue?.reminderEmailDelayHours
  );
  const [reminderEmailSubject, setReminderEmailSubject] = useState(
    venue?.reminderEmailSubject
  );

  const getPatch = () => {
    const emptyHTML = "<p><br></p>";

    return {
      boldTextInAnEmail:
        boldTextInAnEmail === emptyHTML ? "" : boldTextInAnEmail,
      section1Title: section1Title === emptyHTML ? "" : section1Title,
      section2Title: section2Title === emptyHTML ? "" : section2Title,
      section2Paragraph:
        section2Paragraph === emptyHTML ? "" : section2Paragraph,
      delineationTextInAnEmail:
        delineationTextInAnEmail === emptyHTML ? "" : delineationTextInAnEmail,
      eventEmailTitle: eventEmailTitle || "",
      eventEmailCopy: eventEmailCopy || "",
      thankYouEmailEnable,
      thankYouEmailMinutes,
      thankYouEmailSubject,
      thankYouEmailHeader,
      thankYouEmailBody,
      eventInquiryEmailEnable,
      reminderEmailEnable,
      reminderEmailDelayHours,
      reminderEmailSubject,
    };
  };

  const onUpdateGuestEmailConfirmation = (e: React.FormEvent) => {
    e.preventDefault();
    if (venue) {
      const patch = getPatch();
      //todo: send patch only
      saving.current = true;
      updateVenue({ ...venue, ...patch });
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-confirmation"
    >
      <div className="title h5">
        Guest Email Confirmation
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdateGuestEmailConfirmation}>
        <FormHtmlTextField
          label="Section 1 Title"
          className="row"
          value={section1Title}
          setValue={setSection1Title}
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Section 1 Paragraph"
          className="row"
          value={boldTextInAnEmail}
          setValue={setBoldTextInAnEmail}
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Section 2 Title"
          className="row"
          value={section2Title}
          setValue={setSection2Title}
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Section 2 Paragraph"
          className="row"
          value={section2Paragraph}
          setValue={setSection2Paragraph}
          disabled={readonly}
        />
        <FormHtmlTextField
          label="Email Note"
          className="row"
          value={delineationTextInAnEmail}
          setValue={setDelineationTextInAnEmail}
          disabled={readonly}
        />

        <div className="subtitle title h5 pt2">Inquiry Email</div>
        <Toggle
          label="Enable/Disable"
          className="row toggle"
          checked={eventInquiryEmailEnable}
          onChange={() => setEventInquryEmailEnable((prev) => !prev)}
          inlineLabel
          disabled={readonly}
        />
        <FormTextField
          label="Inquiry Email Title"
          className="row"
          value={eventEmailTitle}
          onChange={(_, text) => setEventEmailTitle(text)}
          autoComplete="off"
          disabled={readonly || !eventInquiryEmailEnable}
        />
        <FormHtmlTextField
          label="Inquiry Email Copy"
          className="row"
          value={eventEmailCopy}
          setValue={setEventEmailCopy}
          disabled={readonly || !eventInquiryEmailEnable}
        />

        <div className="subtitle title h5 pt2">Thank You Email</div>
        <Toggle
          label="Enable/Disable"
          className="row toggle"
          checked={thankYouEmailEnable}
          onChange={() => setThankYouEmailEnable((prev) => !prev)}
          inlineLabel
          disabled={readonly}
        />
        <FormTextField
          label="Send after X time (minutes)"
          type="number"
          className="row"
          value={thankYouEmailMinutes?.toString()}
          onChange={(_, text) => setThankYouEmailMinutes(Number(text))}
          autoComplete="off"
          disabled={readonly || !thankYouEmailEnable}
        />
        <FormTextField
          label="Subject"
          className="row"
          value={thankYouEmailSubject}
          onChange={(_, text) => setThankYouEmailSubject(text)}
          autoComplete="off"
          disabled={readonly || !thankYouEmailEnable}
        />
        <FormTextField
          label="Header"
          className="row"
          value={thankYouEmailHeader}
          onChange={(_, text) => setThankYouEmailHeader(text)}
          autoComplete="off"
          disabled={readonly || !thankYouEmailEnable}
        />
        <FormHtmlTextField
          label="Body"
          className="row"
          value={thankYouEmailBody}
          setValue={setThankYouEmailBody}
          disabled={readonly || !thankYouEmailEnable}
        />
        <div className="subtitle title h5 pt2">Reminder Email</div>
        <Toggle
          label="Enable/Disable"
          className="row toggle"
          checked={reminderEmailEnable}
          onChange={() => setReminderEmailEnable((prev) => !prev)}
          inlineLabel
          disabled={readonly}
        />
        <FormTextField
          label="Send before X time (hours)"
          type="number"
          className="row"
          value={reminderEmailDelayHours?.toString()}
          min="1"
          step="1"
          max="24"
          onChange={(_, text) => setReminderEmailDelayHours(Number(text))}
          autoComplete="off"
          disabled={readonly || !reminderEmailEnable}
        />
        <FormTextField
          label="Subject"
          className="row"
          value={reminderEmailSubject}
          onChange={(_, text) => setReminderEmailSubject(text)}
          autoComplete="off"
          disabled={readonly || !reminderEmailEnable}
        />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditConfirmation);
