export enum ReservationStatus {
  Pending = 'pending',
  Ok = 'ok',
  Unpaid = 'unpaid',
  CancelledNoRefund = 'cancelledNoRefund',
  Cancelled = 'cancelled',
  Delete = 'delete',
  Uncaptured = 'uncaptured',
}

export enum PaymentStatus {
  Refund = 'refund',
  Cancelled = 'cancelled',
  Unpaid = 'unpaid',
  Incomplete = 'incomplete',
  Succeeded = 'succeeded',
  Uncaptured = 'uncaptured',
  Modified = 'modified', // do not use it
  Failed = 'failed',
}

export enum PromotionType {
  promotionCode = 'promotionCode',
  groupPromotion = 'groupPromotion',
}

export enum UserType {
  admin = 'admin',
  user = 'user',
  userLead = 'userLead',
  manager = 'manager',
  superAdmin = 'superAdmin',
  adminSpoton = 'adminSpoton',
  venueAdmin = 'venueAdmin',
  adminSteltronic = 'adminSteltronic',
  adminGoTab = 'adminGoTab',
  adminBowlingMarketing = 'adminBowlingMarketing',
  partner = 'partner',
}

export enum TenantPlan {
  lite = 'lite',
  core = 'core',
  enterprise = 'enterprise',
  demo = 'demo',
  development = 'development',
  spotonPartner = 'spotonPartner',
  steltronic = 'Steltronic',
  goTab = 'GoTab',
  bowlingMarketing = 'Bowling Marketing',
  churned = 'churned',
}

export enum PaymentType {
  heartland = 'heartland',
  stripe = 'stripe',
  stripeV2 = 'stripeV2',
  sezzle = 'sezzle',
  fortis = 'fortis',
  goTab = 'goTab',
  external = 'external',
  square = 'square',
}

export enum Pricing {
  perLane = 'Per Lane',
  perPerson = 'Per Person',
  flatRate = 'Flat Rate',
  perPersonFlat = 'Per Person Flat',
  flatRatePerLane = 'Flat Rate (per lane)',
  flatRatePerHour = 'Flat Rate (per hour)',
  ageGroupsPerHour = 'Age Groups (per hour)',
  ageGroupsFlatRate = 'Age Groups (flat rate)',
}

export enum AddonType {
  packageBased = 'packageBased',
  guestCount = 'guestCount',
  resourceBased = 'resourceBased',
}

export enum QuestionType {
  string = 'string',
  dropdown = 'dropdown',
  multiSelect = 'multiSelect',
  date = 'date',
  dateMonth = 'dateMonth',
  email = 'email',
}

export enum ModifierType {
  singleSelect = 'singleSelect',
  multiSelect = 'multiSelect',
}

export enum DepositPercentageType {
  usePackageDeposit = 'usePackageDeposit',
  noDeposit = 'noDeposit',
}

export enum Slot {
  AVAILABLE = 0,
  BOOKED = 1,
  NOT_AVAILABLE = 2,
  BOOKED_AND_AVAILABLE = 3,
  WALK_IN = 4,
  PACKAGE_RESERVATION = 5,
  BLOCK = 6,
}
export enum Table {
  settings = 'settings',
  venues = 'venues',
  schedules = 'schedules',
  timeSlots = 'timeSlots',
  packages = 'packages',
  schedulesPackage = 'schedulesPackage',
  timeSlotsPackage = 'timeSlotsPackage',
  addons = 'addons',
  schedulesAddon = 'schedulesAddon',
  timeSlotsAddon = 'timeSlotsAddon',
  resources = 'resources',
  resourceCategories = 'resourceCategories',
  block = 'block',
  timeSlotsBlock = 'timeSlotsBlock',
  discounts = 'discounts',
  mailchimpMapping = 'mailchimpMapping',
  customersTags = 'customersTags',
  mailchimpTag = 'mailchimpTag',
}
export enum Action {
  creating = 'creating',
  deleting = 'deleting',
  updating = 'updating',
}
export enum ReservationsPeriod {
  all = 'all',
  day = 'day',
  week = 'week',
}
export enum FilterParameter {
  ReservationDate = 'Reservation Date',
  ReservationCreatedDate = 'Reservation Created Date',
  Guests = 'Guests',
  Duration = 'Duration',
  StartTime = 'Start Time',
  ReservationNumber = 'Reservation Number',
  cardLast4 = 'Card Last 4',
}
export enum FilterForGuestsParameter {
  GuestCreatedDate = 'Guest Created Date',
  GuestTags = 'Guest Tags',
  SpendAmount = 'Spend Amount',
  ReservationsAmount = 'Reservations Amount',
}
export enum FilterCondition {
  Equal = 'equal',
  NotEqual = 'not equal',
  Contains = 'contains',
}
export enum FilterEqualCondition {
  Equal = 'equal',
}
export enum FilterConditionDate {
  IsAfter = 'is after',
  IsBefore = 'is before',
  IsOn = 'is on',
}

export enum FilterConditionSimpleArray {
  Equal = 'equal',
  NotEqual = 'not equal',
  Contains = 'contains',
}
export enum FilterConditionDuration {
  Equal = 'equal',
  NotEqual = 'not equal',
  GreaterThan = 'greater than',
  LessThan = 'less than',
}
export enum TripleSeatBlockedStatus {
  Definite = 'DEFINITE',
  Tentative = 'TENTATIVE',
  Prospect = 'PROSPECT',
  Closed = 'CLOSED',
  Lost = 'LOST',
  Waitlist = 'WAITLIST',
}
export enum TripleSeatPublishType {
  Event = 'EVENT',
  Booking = 'BOOKING',
}

export enum TripleSeatAvailabilityType {
  NumberOfGuests = 'NumberOfGuests',
  NumberOfGuaranteedGuests = 'NumberOfGuaranteedGuests',
  NumberOfRooms = 'NumberOfRooms',
  CustomField = 'CustomField',
}

export enum CustomFieldsType {
  String = 'string',
  Date = 'date',
  Number = 'number',
  PlayerDetailsForSteltronic = 'playerDetailsForSteltronic',
  PlayerDetailsNoShoesForSteltronic = 'playerDetailsNoShoesForSteltronic',
  PlayerDetailsNoBumpersForSteltronic = 'playerDetailsNoBumpersForSteltronic',
  PlayerDetailsNoShoesNoBumpersSteltronic = 'playerDetailsNoShoesNoBumpersSteltronic',
  Selector = 'selector',
  PlayerDetailsForTripleSeat = 'playerDetailsForTripleSeat',
  GuestNames = 'guestNames',
  GameSelection = 'gameSelection',
  GameSelectionForStadium = 'gameSelectionForStadium',
  DiningSelection = 'diningSelection',
  Occasion = 'occasion',
  Birthday = 'birthday',
  Email = 'email',
}
export enum TripleSeatCustomReservationFields {
  Prefix = 'Prefix',
  ReservationNumber = 'Reservation Number',
  ReservationName = 'Reservation Name',
  ReservationDate = 'Reservation Date',
  ReservationTime = 'Reservation Time',
  NumberOfGuests = 'Number Of Guests',
  CelebratingAge = 'Celebrating Age',
  GuestOfHonorName = 'Guest Of Honor Name',
  GroupName = 'Group Name',
  Deposit = 'Deposit',
  TotalAmount = 'Total Amount',
  PackageNames = 'Package Names',
  Chaperones = 'Chaperones',
  GuestsPlusChaperones = 'Guests + Chaperones',
  PizzaSelection = 'Pizza Selection',
  PlayerDetails = 'Player Details',
  Custom = 'Custom',
  Address = 'Address',
  GuestNames = 'Guest Names',
  GameSelection = 'Game selection (lawn)',
}

export enum ReservationModeType {
  Default = 'Default',
  PackagesOnly = 'Packages Only',
  ThirdPartyReservations = 'Third Party Reservations',
}

export enum CurrencyType {
  USD = 'USD',
  CAD = 'CAD',
  NZD = 'NZD',
  GBP = 'GBP',
  AUD = 'AUD',
  EUR = 'EUR',
}

export enum StatusInterval {
  HOUR = 1,
  HALF_DAY = 12,
  DAY = 24,
  TREE_DAYS = 72,
  FIVE_DAYS = 120,
  WEEK = 168,
}

export enum FeeType {
  FLAT_RATE = '$',
  PERCENT = '%',
}

export enum InventoryMethodTypes {
  byLanes = 'by lanes',
  byGuests = 'by guests',
  byLanesAndGuests = 'by lanes and guests',
  byResources = 'by resources',
}

export enum ResourceCategoryAssigningMethodType {
  matchGuestCount = 'match guest count',
  custom = 'custom',
  flexibleBasedOnGuests = 'flexible based on guests',
}

export enum PizzaType {
  cheesePizza = 'Cheese Pizza',
  pepperoniPizza = 'Pepperoni Pizza',
  cheeseAndPepperoniPizza = '1/2 Cheese 1/2 Pepperoni',
}
export enum GameType {
  Bocce = 'Bocce',
  Cornhole = 'Cornhole',
  Ladders = 'Ladders',
  KanJam = 'Kan Jam',
  BeersBee = 'Beers Bee',
}
export enum GameTypeForStadium {
  GolfMiniGames = 'Golf Mini Games',
  Football = 'Football',
  Basketball = 'Basketball',
  Baseball = 'Baseball',
  Hockey = 'Hockey',
  Soccer = 'Soccer',
  Lacrosse = 'Lacrosse',
  ZombieDodgeball = 'Zombie Dodgeball',
  CarnivalGames = 'Carnival Games',
  Bocce = 'Bocce',
  Cricket = 'Cricket',
  Rugby = 'Rugby',
}
export enum DiningOptions {
  EatAfterPlay = "We'd like to eat after we play",
  NoFood = "We don't like delicious food",
}
export enum OccasionOptions {
  CompanyParty = 'Company Party',
  KidsParty = 'Kids Party',
  TeenParty = 'Teen Party',
  JustForFun = 'Just for Fun',
  Other = 'Other',
}

export enum ResyncIntegrationType {
  Tripleseat = 'Tripleseat',
  Steltronic = 'Steltronic',
}

export enum CancelType {
  cancelWithRefound = 'Cancel with refund',
  cancelWithoutRefound = 'Cancel without refund',
  deleteReservation = 'Delete Reservation',
}

export enum IntegrationName {
  QSR = 'QSR',
  SevenRooms = 'SevenRooms',
  Seatninja = 'Seatninja',
  Tripleseat = 'Tripleseat',
  MailChimp = 'MailChimp',
  Steltronic = 'Steltronic',
  POS = 'POS',
  SalesForce = 'SalesForce',
  AboutGolf = 'AboutGolf',
  GoTab = 'GoTab',
}

export enum IntegrationStatus {
  Succeeded = 'Succeeded',
  Failed = 'Failed',
}

export enum ReservationTaxType {
  noTax = 'No Tax',
  venueTax = 'Venue Tax',
}

export enum TaxAndFeesTypes {
  tax = 'tax',
  fee = 'fee',
  taxToFee = 'tax to fee',
}

export enum CardColorTypes {
  Light = '#ffffff',
  Dark = '#282a30',
}

export enum DateFormat {
  MMDDYYYY = 'MM/DD/YYYY',
  DDMMYYYY = 'DD/MM/YYYY',
}

export enum CheckInStatus {
  Upcoming = 'Upcoming',
  CheckedIn = 'CheckedIn',
  Completed = 'Completed',
  NoShow = 'NoShow',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
}

export enum BookedStatus {
  upcoming = 'Upcoming',
  checkedIn = 'Checked In',
  completed = 'Completed',
  noShow = 'No Show',
  cancelled = 'Cancelled',
  tripleSeat = 'Tripleseat',
  pending = 'Pending',
}

export enum CouponType {
  fixed = 'fixed',
  percent = 'percent',
}
export enum CouponStatus {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum WeekDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}
export const Monday = WeekDay.Monday;

export enum GoTabWorkflowType {
  ReservationDeposit = 'ReservationDeposit',
  PaidInFull = 'PaidInFull',
}

export enum ReportType {
  ManagersReport = 'ManagersReport',
  TaxReport = 'TaxReport',
  RevenueExport = 'RevenueExport',
  ReservationWithTransactions = 'ReservationWithTransactions',
}

export enum ReportDeliveryType {
  sftp = 'sftp',
  email = 'email',
}

export enum ReportScope {
  prevDayReservations = 'prevDayReservations',
  allReservations = 'allReservations',
}

export enum BlockTimestampStatus {
  CREATED = 'CREATED',
  MODIFIED = 'MODIFIED',
}