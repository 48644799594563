export class VenueNameDto {
    id: string;
    name: string;
    address: string;
    timeZone: string;
    closed: boolean;
    isComingSoon: boolean;
    guestSplit: number;
    maxGuests: number;
    venueImage: string;
    secondaryVenueImages: string[];
    mapImage: string;
    durationInSlots: string;
    sandBoxPayment: boolean;
    enableAssignDuration: boolean;
    assignDuration: string;
    shiftingForReservationStart: number;
    maxGuestsForAdmin?: number;
    maxNumberOfLanes?: number;
    durationInSlotsForAdmin: string;
    durationInSlotsForExtension: string;
    assignDurationForAdmin: string;
    activeCustomFields?: string;
    mandatoryCustomFields?: string;
    packageTags: string[];
}
