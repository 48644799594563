import { RouterState } from "connected-react-router";
import { AuthState } from "./auth";
import { VenuesState } from "./venues";
import { ReservationState } from "./reservation";
import { SettingsState } from "./settings";
import { ReportState } from "./reports";
import { PromotionState } from "./promotions";
import { TenantState } from "./tenants";
import { BookingReservationState } from "./bookingReservation";
import { BookingVenueState } from "./bookingVenue";
import { UIState } from "./ui";
import { PackagesState } from "./packages";
import { SuperAdminReportState } from "./superAdminReports";
import { AddonsState } from "./addons";
import { ResourcesState } from "./resources";
import { ResourceRelationsState } from "./resource-relations";
import { ConfigsState } from "./configs";
import { PartnerState } from "./partners";
import { DiscountsState } from "./discounts";
import { MembershipState } from "./membership";
import { QuestionsState } from "./questions";
import { AddonResourceRelationsState } from "./addon-resource-relations";

export interface State {
  auth: AuthState;
  venues: VenuesState;
  reservation: ReservationState;
  router: RouterState;
  settings: SettingsState;
  reports: ReportState;
  promotions: PromotionState;
  tenants: TenantState;
  partners: PartnerState;
  uiState: UIState;
  bookingReservation: BookingReservationState;
  bookingVenue: BookingVenueState;
  packages: PackagesState;
  superAdminReports: SuperAdminReportState;
  addons: AddonsState;
  resources: ResourcesState;
  resourceRelations: ResourceRelationsState;
  addonResourceRelations: AddonResourceRelationsState;
  configs: ConfigsState;
  discounts: DiscountsState;
  membership: MembershipState;
  questions: QuestionsState
}

export * from "./auth";
export * from "./venues";
export * from "./reservation";
export * from "./settings";
export * from "./reports";
export * from "./promotions";
export * from "./tenants";
export * from "./partners";
export * from "./ui";
export * from "./bookingReservation";
export * from "./bookingVenue";
export * from "./packages";
export * from "./superAdminReports";
export * from "./addons";
export * from "./resources";
export * from "./resource-relations";
export * from "./configs";
export * from "./discounts";
export * from "./membership";
export * from "../../../../server/src/dto/syncLog.dto";
export * from "./questions"
export * from "./addon-resource-relations";