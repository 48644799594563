import { ResourceCategorySettingDto } from "../../../../server/src/dto/resourceCategorySetting.dto"
import { AboutGolfSim, ResourceSettingDto } from "../../../../server/src/dto/resourceSetting.dto"

export * from "../../../../server/src/dto/resourceCategorySetting.dto"
export * from "../../../../server/src/dto/resourceSetting.dto"
export * from "../../../../server/src/dto/resource.dto"

export interface ResourcesState {
    resourceCategories: ResourceCategorySettingDto[];
    isLoadingResourceCategories: boolean;
    error?: string;
    currentResourceCategory?: ResourceCategorySettingDto;
    currentResource?: ResourceSettingDto;
    aboutGolfSims: AboutGolfSim[];
    allResourceCategories: ResourceCategorySettingDto[];
}
