import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { State } from "../store/types";

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  State,
  unknown,
  Action<string>
>;

export enum ActionTypeEnum {
  // auth
  AuthRequest = "auth/AUTH_REQUEST",
  AuthSuccess = "auth/AUTH_SUCCESS",
  AuthFailure = "auth/AUTH_FAILURE",
  ForgetPasswordRequest = "auth/FORGET_PASSWORD_REQUEST",
  ForgetPasswordSuccess = "auth/FORGET_PASSWORD_SUCCESS",
  ForgetPasswordFailure = "auth/FORGET_PASSWORD_FAILURE",
  Logout = "auth/LOGOUT",
  GetAdminType = "auth/GET_ADMIN_TYPE",
  GetAdminTypeSuccess = "auth/GET_ADMIN_TYPE_SUCCESS",
  GetAdminTypeFailure = "auth/GET_ADMIN_TYPE_FAILURE",
  //users
  GetUsers = "users/GET_USERS",
  GetUsersSuccess = "users/GET_USERS_SUCCESS",
  GetUsersFailure = "users/GET_USERS_FAILURE",
  SelectUser = "users/SELECT_USER",
  CreateTenantUser = "users/CREATE_TENANT_USER",
  CreateTenantUserSuccess = "users/CREATE_TENANT_USER_SUCCESS",
  CreateTenantUserFailure = "users/CREATE_TENANT_USER_FAILURE",
  UpdateTenantUser = "users/UPDATE_TENANT_USER",
  UpdateTenantUserSuccess = "users/UPDATE_TENANT_USER_SUCCESS",
  UpdateTenantUserFailure = "users/UPDATE_TENANT_USER_FAILURE",
  RemoveTenantUser = "users/REMOVE_TENANT_USER",
  RemoveTenantUserSuccess = "users/REMOVE_TENANT_USER_SUCCESS",
  RemoveTenantUserFailure = "users/REMOVE_TENANT_USER_FAILURE",
  UsersDownloadRequest = "users/DOWNLOAD_REQUEST",
  UsersDownloadSuccess = "users/DOWNLOAD_REQUEST_SUCCESS",
  UsersDownloadFailure = "users/DOWNLOAD_REQUEST_FAILURE",

  //error
  addError = "error/ADD_ERROR",
  dismissError = "error/DISMISS_ERROR",

  //ui
  setWebConfigUI = "ui/SET_UI",
  setVenuePage = "ui/SET_VENUE_PAGE",

  //venues
  GetVenues = "venue/GET_VENUES",
  GetVenuesSuccess = "venue/GET_VENUES_SUCCESS",
  GetVenuesFailure = "venue/GET_VENUES_FAILURE",
  CreateVenue = "venue/CREATE_VENUE",
  CreateVenueSuccess = "venue/CREATE_VENUE_SUCCESS",
  CreateVenueFailure = "venue/CREATE_VENUE_FAILURE",
  RemoveVenue = "venue/REMOVE_VENUE",
  RemoveVenueSuccess = "venue/REMOVE_VENUE_SUCCESS",
  RemoveVenueFailure = "venue/REMOVE_VENUE_FAILURE",
  GetVenue = "venue/GET_VENUE",
  GetVenueSuccess = "venue/GET_VENUE_SUCCESS",
  GetVenueFailure = "venue/GET_VENUE_FAILURE",
  UpdateVenue = "venue/UPDATE_VENUE",
  UpdateVenueSuccess = "venue/UPDATE_VENUE_SUCCESS",
  UpdateVenueFailure = "venue/UPDATE_VENUE_FAILURE",
  CloneVenue = "venue/CLONE_VENUE",
  CloneVenueSuccess = "venue/CLONE_VENUE_SUCCESS",
  CloneVenueFailure = "venue/CLONE_VENUE_FAILURE",
  SetFilteredVenues = "venue/SET_FILTERED_VENUES",
  GetVenueImageUrl = "venue/GET_VENUE_IMAGE_URL",
  GetVenueImageSuccess = "venue/GET_VENUE_IMAGE_SUCCESS",
  GetVenueImageFailure = "venue/GET_VENUE_IMAGE_FAILURE",
  GetVenueMapImageUrl = "venue/GET_VENUE_MAP_IMAGE_URL",
  GetVenueMapImageSuccess = "venue/GET_VENUE_MAP_IMAGE_SUCCESS",
  GetVenueMapImageFailure = "venue/GET_VENUE_MAP_IMAGE_FAILURE",
  SetVenueError = "venue/SET_VENUE_ERROR",
  UpdateVenueTaxes = "venue/UPDATE_VENUE_TAXES",
  UpdateVenueTaxesSuccess = "venue/UPDATE_VENUE_TAXES_SUCCESS",
  UpdateVenueTaxesFailure = "venue/UPDATE_VENUE_TAXES_FAILURE",
  GetGoTabProducts = "venue/GET_GO_TAB_PRODUCTS",
  GetGoTabProductsSuccess = "venue/GET_GO_TAB_PRODUCTS_SUCCESS",
  GetGoTabProductsFailure = "venue/GET_GO_TAB_PRODUCTS_FAILURE",

  //venue Mailchimp
  GetListsInfo = "venue/GET_LISTS_INFO",
  GetListsInfoSuccess = "venue/GET_LISTS_INFO_SUCCESS",
  GetListsInfoFailure = "venue/GET_LISTS_INFO_FAILURE",
  GetMergeFields = "venue/GET_MERGE_FIELDS",
  GetMergeFieldsSuccess = "venue/GET_MERGE_FIELDS_SUCCESS",
  GetMergeFieldsFailure = "venue/GET_MERGE_FIELDS_FAILURE",
  CreateMailchimpMapping = "venue/CREATE_MAILCHIMP_MAPPING",
  CreateMailchimpMappingSuccess = "venue/CREATE_MAILCHIMP_MAPPING_SUCCESS",
  CreateMailchimpMappingFailure = "venue/CREATE_MAILCHIMP_MAPPING_FAILURE",
  UpdateMailchimpMapping = "venue/UPDATE_MAILCHIMP_MAPPING",
  UpdateMailchimpMappingSuccess = "venue/UPDATE_MAILCHIMP_MAPPING_SUCCESS",
  UpdateMailchimpMappingFailure = "venue/UPDATE_MAILCHIMP_MAPPING_FAILURE",
  CreateMailchimpTag = "venue/CREATE_MAILCHIMP_TAG",
  CreateMailchimpTagSuccess = "venue/CREATE_MAILCHIMP_TAG_SUCCESS",
  CreateMailchimpTagFailure = "venue/CREATE_MAILCHIMP_TAG_FAILURE",
  UpdateMailchimpTag = "venue/UPDATE_MAILCHIMP_TAG",
  UpdateMailchimpTagSuccess = "venue/UPDATE_MAILCHIMP_TAG_SUCCESS",
  UpdateMailchimpTagFailure = "venue/UPDATE_MAILCHIMP_TAG_FAILURE",
  GetMailchimpTags = "venue/GET_MAILCHIMP_TAGS",
  GetMailchimpTagsSuccess = "venue/GET_MAILCHIMP_TAGS_SUCCESS",
  GetMailchimpTagsFailure = "venue/GET_MAILCHIMP_TAGS_FAILURE",

  //venue schedule
  UpdateSchedule = "venue/UPDATE_SCHEDULE",
  UpdateScheduleSuccess = "venue/UPDATE_SCHEDULE_SUCCESS",
  UpdateScheduleFailure = "venue/UPDATE_SCHEDULE_FAILURE",
  RemoveSchedule = "venue/REMOVE_SCHEDULE",
  RemoveScheduleSuccess = "venue/REMOVE_SCHEDULE_SUCCESS",
  RemoveScheduleFailure = "venue/REMOVE_SCHEDULE_FAILURE",

  GetCurrentSchedule = "venue/GET_CURRENT_SCHEDULE",
  GetCurrentScheduleSuccess = "venue/GET_CURRENT_SCHEDULE_SUCCESS",
  GetCurrentScheduleFailure = "venue/GET_CURRENT_SCHEDULE_FAILURE",

  //Reservation
  GetReservationsRequest = "reservation/GET_RESERVATIONS_REQUEST",
  GetReservationsSuccess = "reservation/GET_RESERVATIONS_SUCCESS",
  GetReservationsFailure = "reservation/GET_RESERVATIONS_FAILURE",
  GetReservationsTotalCountSuccess = "reservation/GET_TOTAL_COUNT_SUCCESS",

  SetNumberOfSearchingReservation = "reservation/SET_CONFIRMATION_NUMBER_FOR_SEARCHING",
  SetLastLoadedPage = "reservation/SET_LAST_LOADED_PAGE",
  SetPeriodOfReservations = "reservation/SET_PERIOD_OF_RESERVATIONS",
  SetVenueIdFilterReservations = "reservation/SET_VENUE_ID_FOR_FILTER_RESERVATIONS",
  SetVenueIdFilterReports = "reservation/SET_VENUE_ID_FOR_FILTER_REPORTS",
  SetVenueIdsFilterReportsV2 = "reservation/SET_VENUE_IDS_FILTER_REPORTS_V2",
  SetColumns = "reservation/SET_COLUMNS",
  SelectReservation = "reservation/SELECT_RESERVATION",

  ResyncIntegration = "reservation/RESYNC_INTEGRATION",
  ResyncIntegrationSuccess = "reservation/RESYNC_INTEGRATION_SUCCESS",
  ResyncIntegrationFailure = "reservation/RESYNC_INTEGRATION_FAILURE",

  ResendConfirmationEmail = "reservation/RESEND_CONFIRMATION_EMAIL",
  ResendConfirmationEmailSuccess = "reservation/RESEND_CONFIRMATION_EMAIL_SUCCESS",
  ResendConfirmationEmailFailure = "reservation/RESEND_CONFIRMATION_EMAIL_FAILURE",

  GetReservationByResNumber = "reservation/GET_RESERVATION_BY_RES_NUMBER",
  GetReservationByResNumberSuccess = "reservation/GET_RESERVATION_BY_RES_NUMBER_SUCCESS",
  GetReservationByResNumberFailure = "reservation/GET_RESERVATION_BY_RES_NUMBER_FAILURE",

  SetFilterParametersForReservations = "reservation/SET_FILTER_PARAMETERS_FOR_RESERVATIONS",

  SyncReservationsRequest = "reservation/SYNC_RESERVATIONS_REQUEST",
  SyncReservationsSuccess = "reservation/SYNC_RESERVATIONS_SUCCESS",
  SyncReservationsFailure = "reservation/SYNC_RESERVATIONS_FAILURE",

  //Transaction
  GetTransactions = "reservation/GET_TRANSACTION",
  GetTransactionsSuccess = "reservation/GET_TRANSACTION_SUCCESS",
  GetTransactionsFailure = "reservation/GET_TRANSACTION_FAILURE",

  SetTransactionAndReservationClear = "reservation/SET_TRANSACTION_AND_RESERVATION_CLEAR",

  RefundTransaction = "reservation/REFUND_TRANSACTION",
  RefundTransactionSuccess = "reservation/REFUND_TRANSACTION_SUCCESS",
  RefundTransactionFailure = "reservation/REFUND_TRANSACTION_FAILURE",

  CancelReservation = "reservation/CANCEL_RESERVATION",
  CancelReservationSuccess = "reservation/CANCEL_RESERVATION_SUCCESS",
  CancelReservationFailure = "reservation/CANCEL_RESERVATION_FAILURE",
  RemoveReservation = "reservation/REMOVE_RESERVATION",
  RemoveReservationSuccess = "reservation/REMOVE_RESERVATION_SUCCESS",
  RemoveReservationFailure = "reservation/REMOVE_RESERVATION_FAILURE",

  //Settings
  GetSettings = "settings/GET",
  GetSettingsSuccess = "settings/GET_SUCCESS",
  GetSettingsFailure = "settings/GET_FAILURE",
  UpdateSettings = "settings/UPDATE",
  UpdateSettingsSuccess = "settings/UPDATE_SUCCESS",
  UpdateSettingsFailure = "settings/UPDATE_FAILURE",
  SetError = "settings/SET_ERROR",

  //square
  DisableSquare = "settings/DISABLE_SQUARE",
  DisableSquareSuccess = "settings/DISABLE_SQUARE_SUCCESS",
  DisableSquareFailure = "settings/DISABLE_SQUARE_FAILURE",
  GetSquareOAuthUrl = "settings/GET_SQUARE_O_AUTH_URL",
  GetSquareOAuthUrlSuccess = "settings/GET_SQUARE_O_AUTH_URL_SUCCESS",
  GetSquareOAuthUrlFailure = "settings/GET_SQUARE_O_AUTH_URL_FAILURE",
  GetSquareLocations = "venues/GET_SQUARE_LOCATIONS",
  GetSquareLocationsSuccess = "venues/GET_SQUARE_LOCATIONS_SUCCESS",
  GetSquareLocationsFailure = "venues/GET_SQUARE_LOCATIONS_FAILURE",

  //Mailchimp
  GetMailchimpOAuthUrl = "settings/GET_MAILCHIMP_O_AUTH_URL",
  GetMailchimpOAuthUrlSuccess = "settings/GET_MAILCHIMP_O_AUTH_URL_SUCCESS",
  GetMailchimpOAuthUrlFailure = "settings/GET_MAILCHIMP_O_AUTH_URL_FAILURE",
  DisableMailchimp = "settings/DISABLE_MAILCHIMP",
  DisableMailchimpSuccess = "settings/DISABLE_MAILCHIMP_SUCCESS",
  DisableMailchimpFailure = "settings/DISABLE_MAILCHIMP_FAILURE",


  //stripe
  ConnectStripe = "settings/CONNECT_STRIPE",
  ConnectStripeSuccess = "settings/CONNECT_STRIPE_SUCCESS",
  ConnectStripeFailure = "settings/CONNECT_STRIPE_FAILURE",
  DisconnectStripe = "settings/DISCONNECT_STRIPE",
  DisconnectStripeSuccess = "settings/DISCONNECT_STRIPE_SUCCESS",
  DisconnectStripeFailure = "settings/DISCONNECT_STRIPE_FAILURE",
  CheckStripe = "settings/CHECK_STRIPE",
  CheckStripeWithoutRequestInProgress = "settings/CHECK_STRIPE_WITHOUT_REQUEST_IN_PROGRESS",
  CheckStripeSuccess = "settings/CHECK_STRIPE_SUCCESS",
  CheckStripeFailure = "settings/CHECK_STRIPE_FAILURE",
  VenueConnectStripe = "venue/CONNECT_STRIPE",
  VenueConnectStripeSuccess = "venue/CONNECT_STRIPE_SUCCESS",
  VenueConnectStripeFailure = "venue/CONNECT_STRIPE_FAILURE",
  VenueDisconnectStripe = "venue/DISCONNECT_STRIPE",
  VenueDisconnectStripeSuccess = "venue/DISCONNECT_STRIPE_SUCCESS",
  VenueDisconnectStripeFailure = "venue/DISCONNECT_STRIPE_FAILURE",
  VenueCheckStripe = "venue/CHECK_STRIPE",
  VenueCheckStripeWithoutRequestInProgress = "venue/CHECK_STRIPE_WITHOUT_REQUEST_IN_PROGRESS",
  VenueCheckStripeSuccess = "venue/CHECK_STRIPE_SUCCESS",
  VenueCheckStripeFailure = "venue/CHECK_STRIPE_FAILURE",

  GetHomeConfig = "config/GET_HOME_CONFIG",
  GetHomeConfigSuccess = "config/GET_HOME_CONFIG_SUCCESS",
  GetHomeConfigFailure = "config/GET_HOME_CONFIG_FAILURE",
  UpdateHomeConfig = "config/UPDATE_HOME_CONFIG",
  UpdateHomeConfigSuccess = "config/UPDATE_HOME_CONFIG_SUCCESS",
  UpdateHomeConfigFailure = "config/UPDATE_HOME_CONFIG_FAILURE",
  GetHomeImageUrl = "config/GET_HOME_IMAGE_URL",
  GetHomeImageUrlSuccess = "config/GET_HOME_IMAGE_URL_SUCCESS",
  GetHomeImageUrlFailure = "config/GET_HOME_IMAGE_URL_FAILURE",

  GetHomeLogoUrl = "config/GET_HOME_LOGO_URL",
  GetHomeLogoUrlSuccess = "config/GET_HOME_LOGO_URL_SUCCESS",
  GetHomeLogoUrlFailure = "config/GET_HOME_LOGO_URL_FAILURE",
  GetHomeEmailLogoUrl = "config/GET_HOME_EMAIL_LOGO_URL",
  GetHomeEmailLogoUrlSuccess = "config/GET_HOME_EMAIL_LOGO_URL_SUCCESS",
  GetHomeEmailLogoUrlFailure = "config/GET_HOME_EMAIL_LOGO_URL_FAILURE",
  GetHomeFaviconUrl = "config/GET_HOME_FAVICON_URL",
  GetHomeFaviconUrlSuccess = "config/GET_HOME_FAVICON_URL_SUCCESS",
  GetHomeFaviconUrlFailure = "config/GET_HOME_FAVICON_URL_FAILURE",
  GetHomeLogoLink = "config/GET_HOME_LOGO_LINK",
  GetHomeLogoLinkSuccess = "config/GET_HOME_LOGO_LINK_SUCCESS",
  GetHomeLogoLinkFailure = "config/GET_HOME_LOGO_LINK_FAILURE",

  //Square
  GetSquareCatalogs = "venue/GET_SQUARE_CATALOGS",
  GetSquareCatalogsSuccess = "venue/GET_SQUARE_CATALOGS_SUCCESS",
  GetSquareCatalogsFailure = "venue/GET_SQUARE_CATALOGS_FAILURE",
  ClearSquareCatalogs = "venue/CLEAR_SQUARE_CATALOGS",

  //Report Settings
  GetReportSettingsRequest = "report-settings/GET",
  GetReportSettingsSuccess = "report-settings/GET_SUCCESS",
  GetReportSettingsFailure = "report-settings/GET_FAILURE",
  SaveReportSettingsRequest = "report-settings/SAVE",
  SaveReportSettingsSuccess = "report-settings/SAVE_SUCCESS",
  SaveReportSettingsFailure = "report-settings/SAVE_FAILURE",


  //Reports
  GetReportsRequest = "reports/GET",
  GetReportsSuccess = "reports/GET_SUCCESS",
  GetReportsFailure = "reports/GET_FAILURE",
  SetReportInterval = "reports/SET_INTERVAL",
  ReportDownloadRequest = "reports/DOWNLOAD_REQUEST",
  ReportDownloadSuccess = "reports/DOWNLOAD_REQUEST_SUCCESS",
  ReportDownloadFailure = "reports/DOWNLOAD_REQUEST_FAILURE",
  SetFilterParametersForReport = "reports/SET_FILTER_PARAMETERS_FOR_REPORT",
  GetReportsV2Request = "reports/GET_REPORTS_V2_REQUEST",
  GetReportsV2Success = "reports/GET_REPORTS_V2_SUCCESS",
  GetReportsV2Failure = "reports/GET_REPORTS_V2_FAILURE",

  //Super admin reports
  GetSuperAdminReportsRequest = "reports/GET_SUPER_ADMIN_REPORT",
  GetSuperAdminReportsSuccess = "reports/GET_SUPER_ADMIN_REPORT_SUCCESS",
  GetSuperAdminReportsFailure = "reports/GET_SUPER_ADMIN_REPORT_FAILURE",
  SetSuperAdminReportInterval = "reports/SET_SUPER_ADMIN_REPORT_INTERVAL",
  SetSuperAdminReportCurrencyFilter = "reports/SET_SUPER_ADMIN_REPORT_CURRENCY_FILTER",
  GetAppStatusRequest = "reports/GET_APP_STATUS_REPORT",
  GetAppStatusSuccess = "reports/GET_APP_STATUS_SUCCESS",
  GetAppStatusFailure = "reports/GET_APP_STATUS_FAILURE",

  //Promotions
  GetPromotionsRequest = "promotions/GET",
  GetPromotionsSuccess = "promotions/GET_SUCCESS",
  GetPromotionsFailure = "promotions/GET_FAILURE",
  CreatePromotionRequest = "promotions/CREATE_PROMOTION",
  CreatePromotionSuccess = "promotions/CREATE_PROMOTION_SUCCESS",
  CreatePromotionFailure = "promotions/CREATE_PROMOTION_FAILURE",
  RemovePromotion = "promotions/REMOVE_PROMOTION",
  RemovePromotionSuccess = "promotions/REMOVE_PROMOTION_SUCCESS",
  RemovePromotionFailure = "promotions/REMOVE_PROMOTION_FAILURE",
  UpdatePromotion = "promotions/UPDATE_PROMOTION",
  UpdatePromotionSuccess = "promotions/UPDATE_PROMOTION_SUCCESS",
  UpdatePromotionFailure = "promotions/UPDATE_PROMOTION_FAILURE",
  GetPromotion = "promotions/GET_PROMOTION",

  //Tenants
  GetTenants = "tenants/GET_ALL",
  GetTenantsSuccess = "tenants/GET_ALL_SUCCESS",
  GetTenantsFailure = "tenants/GET_ALL_FAILURE",
  CreateTenant = "tenants/CREATE",
  CreateTenantSuccess = "tenants/CREATE_SUCCESS",
  CreateTenantFailure = "tenants/CREATE_FAILURE",
  GetTenant = "tenants/GET",
  UpdateTenant = "tenants/UPDATE",
  UpdateTenantSuccess = "tenants/UPDATE_SUCCESS",
  UpdateTenantFailure = "tenants/UPDATE_FAILURE",
  CloneTenant = "tenants/CLONE",
  CloneTenantSuccess = "tenants/CLONE_SUCCESS",
  CloneTenantFailure = "tenants/CLONE_FAILURE",
  DeleteTenant = "tenants/DELETE",
  DeleteTenantSuccess = "tenants/DELETE_SUCCESS",
  DeleteTenantFailure = "tenants/DELETE_FAILURE",
  GetTenantSuccess = "tenants/GET_SUCCESS",
  GetTenantFailure = "tenants/GET_FAILURE",
  SetTenant = "tenants/SELECT",
  SetTenantError = 'tenants/SET_ERROR',
  CreateUser = 'users/CREATE',
  CreateUserSuccess = 'users/CREATE_SUCCESS',
  CreateUserFailure = 'users/CREATE_FAILURE',
  UpdateUser = 'users/UPDATE',
  UpdateUserSuccess = 'users/UPDATE_SUCCESS',
  UpdateUserFailure = 'users/UPDATE_FAILURE',
  RemoveUser = 'users/REMOVE',
  RemoveUserSuccess = 'users/REMOVE_SUCCESS',
  RemoveUserFailure = 'users/REMOVE_FAILURE',

  GetUIConfig = 'ui/GET_UI_CONFIG',
  GetUIConfigSuccess = 'ui/GET_UI_CONFIG_SUCCESS',
  GetUIConfigFailure = 'ui/GET_UI_CONFIG_FAILURE',
  SetTenantIdFilterReports = "tenant/SET_TENANT_ID_FOR_FILTER_REPORTS",
  SetTenantPlanFilterReports = 'tenant/SET_TENANT_PLAN_FOR_FILTER_REPORTS',

  //packages
  GetPackages = "package/GET_PACKAGES",
  GetPackagesSuccess = "package/GET_PACKAGES_SUCCESS",
  GetPackagesFailure = "package/GET_PACKAGES_FAILURE",
  CreatePackage = "package/CREATE_PACKAGE",
  CreatePackageSuccess = "package/CREATE_PACKAGE_SUCCESS",
  CreatePackageFailure = "package/CREATE_PACKAGE_FAILURE",
  RemovePackage = "package/REMOVE_PACKAGE",
  RemovePackageSuccess = "package/REMOVE_PACKAGE_SUCCESS",
  RemovePackageFailure = "package/REMOVE_PACKAGE_FAILURE",
  GetPackage = "package/GET_PACKAGE",
  GetPackageSuccess = "package/GET_PACKAGE_SUCCESS",
  GetPackageFailure = "package/GET_PACKAGE_FAILURE",
  UpdatePackage = "package/UPDATE_PACKAGE",
  UpdatePackageSuccess = "package/UPDATE_PACKAGE_SUCCESS",
  UpdatePackageFailure = "package/UPDATE_PACKAGE_FAILURE",
  ClonePackage = "package/CLONE_PACKAGE",
  ClonePackageSuccess = "package/CLONE_PACKAGE_SUCCESS",
  ClonePackageFailure = "package/CLONE_PACKAGE_FAILURE",
  GetPackageImageUrl = "package/GET_PACKAGE_IMAGE_URL",
  GetPackageImageSuccess = "package/GET_PACKAGE_IMAGE_SUCCESS",
  GetPackageImageFailure = "package/GET_PACKAGE_IMAGE_FAILURE",
  SetPackageError = "package/SET_PACKAGE_ERROR",
  SetFilteredPackages = "package/SET_FILTERED_PACKAGES",

  //package schedule
  UpdateSchedulePackage = "package/UPDATE_SCHEDULE",
  UpdateSchedulePackageSuccess = "package/UPDATE_SCHEDULE_SUCCESS",
  UpdateSchedulePackageFailure = "package/UPDATE_SCHEDULE_FAILURE",
  RemoveSchedulePackage = "package/REMOVE_SCHEDULE",
  RemoveSchedulePackageSuccess = "package/REMOVE_SCHEDULE_SUCCESS",
  RemoveSchedulePackageFailure = "package/REMOVE_SCHEDULE_FAILURE",

  GetCurrentPackageSchedule = "package/GET_CURRENT_PACKAGE_SCHEDULE",
  GetCurrentPackageScheduleSuccess = "package/GET_CURRENT_PACKAGE_SCHEDULE_SUCCESS",
  GetCurrentPackageScheduleFailure = "package/GET_CURRENT_PACKAGE_SCHEDULE_FAILURE",

  GetParentPackageSchedule = "package/GET_PARENT_PACKAGE_SCHEDULE",
  GetParentPackageScheduleSuccess = "package/GET_PARENT_PACKAGE_SCHEDULE_SUCCESS",
  GetParentPackageScheduleFailure = "package/GET_PARENT_PACKAGE_SCHEDULE_FAILURE",

  //addons
  GetAddons = "addon/GET_ADDONS",
  GetAddonsSuccess = "addon/GET_ADDONS_SUCCESS",
  GetAddonsFailure = "addon/GET_ADDONS_FAILURE",
  GetAddon = "addon/GET_ADDON",
  GetAddonSuccess = "addon/GET_ADDON_SUCCESS",
  GetAddonFailure = "addon/GET_ADDON_FAILURE",
  RemoveScheduleAddon = "addon/REMOVE_SCHEDULE",
  RemoveScheduleAddonSuccess = "addon/REMOVE_SCHEDULE_SUCCESS",
  RemoveScheduleAddonFailure = "addon/REMOVE_SCHEDULE_FAILURE",
  GetAddonsRelations = "addon/GET_ADDONS_PACKAGES",
  GetAddonsRelationsSuccess = "addon/GET_ADDONS_PACKAGES_SUCCESS",
  GetAddonsRelationsFailure = "addon/GET_ADDONS_PACKAGES_FAILURE",
  SetFilteredAddons = "addon/SET_FILTERED_ADDONS",
  CreateAddon = "addon/CREATE_ADDON",
  CreateAddonSuccess = "addon/CREATE_ADDON_SUCCESS",
  CreateAddonFailure = "addon/CREATE_ADDON_FAILURE",
  UpdateAddon = "addon/UPDATE_ADDON",
  UpdateAddonSuccess = "addon/UPDATE_ADDON_SUCCESS",
  UpdateAddonFailure = "addon/UPDATE_ADDON_FAILURE",
  UpdateAddonRelations = "addon/UPDATE_ADDON_RELATIONS",
  UpdateAddonRelationsSuccess = "addon/UPDATE_ADDON_RELATIONS_SUCCESS",
  UpdateAddonRelationsFailure = "addon/UPDATE_ADDON_RELATIONS_FAILURE",
  CloneAddon = "addon/CLONE_ADDON",
  CloneAddonSuccess = "addon/CLONE_ADDON_SUCCESS",
  CloneAddonFailure = "addon/CLONE_ADDON_FAILURE",
  RemoveAddon = "addon/REMOVE_ADDON",
  RemoveAddonSuccess = "addon/REMOVE_ADDON_SUCCESS",
  RemoveAddonFailure = "addon/REMOVE_ADDON_FAILURE",
  GetAddonImageUrl = "addon/GET_ADDON_IMAGE_URL",
  GetAddonImageSuccess = "addon/GET_ADDON_IMAGE_URL_SUCCESS",
  GetAddonImageFailure = "addon/GET_ADDON_IMAGE_URL_FAILURE",
  GetAddonModifiers = "addon/GET_ADDON_MODIFIERS",
  GetAddonModifiersSuccess = "addon/GET_ADDON_MODIFIERS_SUCCESS",
  GetAddonModifiersFailure = "addon/GET_ADDON_MODIFIERS_FAILURE",
  UpdateAddonModifiers = "addon/UPDATE_ADDON_MODIFIERS",
  UpdateAddonModifiersSuccess = "addon/UPDATE_ADDON_MODIFIERS_SUCCESS",
  UpdateAddonModifiersFailure = "addon/UPDATE_ADDON_MODIFIERS_FAILURE",
  CreateModifier = "addon/CREATE_MODIFIER",
  CreateModifierSuccess = "addon/CREATE_MODIFIER_SUCCESS",
  CreateModifierFailure = "addon/CREATE_MODIFIER_FAILURE",
  UpdateModifier = "addon/UPDATE_MODIFIER",
  UpdateModifierSuccess = "addon/UPDATE_MODIFIER_SUCCESS",
  UpdateModifierFailure = "addon/UPDATE_MODIFIER_FAILURE",

  DeleteAddonModifiers = "addon/DELETE_ADDON_MODIFIERS",
  DeleteAddonModifiersSuccess = "addon/DELETE_ADDON_MODIFIERS_SUCCESS",
  DeleteAddonModifiersFailure = "addon/DELETE_ADDON_MODIFIERS_FAILURE",
  SelectAddonModifier = "addon/SELECT_ADDON_MODIFIERS",

  //discounts
  GetDiscounts = "discount/GET_DISCOUNTS",
  GetDiscountsSuccess = "discount/GET_DISCOUNTS_SUCCESS",
  GetDiscountsFailure = "discount/GET_DISCOUNTS_FAILURE",
  GetDiscount = "discount/GET_DISCOUNT",
  GetDiscountSuccess = "discount/GET_DISCOUNT_SUCCESS",
  GetDiscountFailure = "discount/GET_DISCOUNT_FAILURE",
  RemoveScheduleDiscount = "discount/REMOVE_SCHEDULE",
  RemoveScheduleDiscountSuccess = "discount/REMOVE_SCHEDULE_SUCCESS",
  RemoveScheduleDiscountFailure = "discount/REMOVE_SCHEDULE_FAILURE",
  GetDiscountsRelations = "discount/GET_DISCOUNTS_PACKAGES",
  GetDiscountsRelationsSuccess = "discount/GET_DISCOUNTS_PACKAGES_SUCCESS",
  GetDiscountsRelationsFailure = "discount/GET_DISCOUNTS_PACKAGES_FAILURE",
  CreateDiscount = "discount/CREATE_DISCOUNT",
  CreateDiscountSuccess = "discount/CREATE_DISCOUNT_SUCCESS",
  CreateDiscountFailure = "discount/CREATE_DISCOUNT_FAILURE",
  UpdateDiscount = "discount/UPDATE_DISCOUNT",
  UpdateDiscountSuccess = "discount/UPDATE_DISCOUNT_SUCCESS",
  UpdateDiscountFailure = "discount/UPDATE_DISCOUNT_FAILURE",
  UpdateDiscountRelations = "discount/UPDATE_DISCOUNT_RELATIONS",
  UpdateDiscountRelationsSuccess = "discount/UPDATE_DISCOUNT_RELATIONS_SUCCESS",
  UpdateDiscountRelationsFailure = "discount/UPDATE_DISCOUNT_RELATIONS_FAILURE",
  GetDiscountsSchedule = "discount/GET_DISCOUNTS_SCHEDULE",
  GetDiscountsScheduleSuccess = "discount/GET_DISCOUNTS_SCHEDULE_SUCCESS",
  GetDiscountsScheduleFailure = "discount/GET_DISCOUNTS_SCHEDULE_FAILURE",
  UpdateDiscountSchedule = "discount/UPDATE_DISCOUNT_SCHEDULE",
  UpdateDiscountScheduleSuccess = "discount/UPDATE_DISCOUNT_SCHEDULE_SUCCESS",
  UpdateDiscountScheduleFailure = "discount/UPDATE_DISCOUNT_SCHEDULE_FAILURE",
  GetDiscountAddons = "discount/GET_DISCOUNTS_ADDONS",
  GetDiscountAddonsSuccess = "discount/GET_DISCOUNTS_ADDONS_SUCCESS",
  GetDiscountAddonsFailure = "discount/GET_DISCOUNTS_ADDONS_FAILURE",
  UpdateDiscountAddons = "discount/UPDATE_DISCOUNT_ADDONS",
  UpdateDiscountAddonsSuccess = "discount/UPDATE_DISCOUNT_ADDONS_SUCCESS",
  UpdateDiscountAddonsFailure = "discount/UPDATE_DISCOUNT_ADDONS_FAILURE",

  RemoveDiscount = "discount/REMOVE_DISCOUNT",
  RemoveDiscountSuccess = "discount/REMOVE_DISCOUNT_SUCCESS",
  RemoveDiscountFailure = "discount/REMOVE_DISCOUNT_FAILURE",

  //resources
  GetResourceCategories = "resource/GET_RESOURCE_CATEGORIES",
  GetResourceCategoriesSuccess = "resource/GET_RESOURCE_CATEGORIES_SUCCESS",
  GetResourceCategoriesFailure = "resource/GET_RESOURCE_CATEGORIES_FAILURE",
  GetAllResourceCategories = "resource/GET_ALL_RESOURCE_CATEGORIES",
  GetAllResourceCategoriesSuccess = "resource/GET_ALL_RESOURCE_CATEGORIES_SUCCESS",
  GetAllResourceCategoriesFailure = "resource/GET_ALL_RESOURCE_CATEGORIES_FAILURE",
  UpdateResourceCategory = "resource/UPDATE_RESOURCE_CATEGORY",
  UpdateResourceCategorySuccess = "resource/UPDATE_RESOURCE_CATEGORY_SUCCESS",
  UpdateResourceCategoryFailure = "resource/UPDATE_RESOURCE_CATEGORY_FAILURE",
  DeleteResourceCategory = "resource/DELETE_RESOURCE_CATEGORY",
  DeleteResourceCategorySuccess = "resource/DELETE_RESOURCE_CATEGORY_SUCCESS",
  DeleteResourceCategoryFailure = "resource/DELETE_RESOURCE_CATEGORY_FAILURE",
  SetCurrentResourceCategory = "resource/SET_CURRENT_RESOURCE_CATEGORY",
  AddResourceCategory = "resource/ADD_RESOURCE_CATEGORY",
  AddResourceCategorySuccess = "resource/ADD_RESOURCE_CATEGORY_SUCCESS",
  AddResourceCategoryFailure = "resource/ADD_RESOURCE_CATEGORY_FAILURE",
  AddResource = "resource/ADD_RESOURCE",
  AddResourceSuccess = "resource/ADD_RESOURCE_SUCCESS",
  AddResourceFailure = "resource/ADD_RESOURCE_FAILURE",
  SetCurrentResource = "resource/SET_CURRENT_RESOURCE",
  UpdateResource = "resource/UPDATE_RESOURCE",
  UpdateResourceSuccess = "resource/UPDATE_RESOURCE_SUCCESS",
  UpdateResourceFailure = "resource/UPDATE_RESOURCE_FAILURE",
  DeleteResource = "resource/DELETE_RESOURCE",
  DeleteResourceSuccess = "resource/DELETE_RESOURCE_SUCCESS",
  DeleteResourceFailure = "resource/DELETE_RESOURCE_FAILURE",
  GetAboutGolfSims = "resource/GET_ABOUT_GOLF_SIMS",
  GetAboutGolfSimsSuccess = "resource/GET_ABOUT_GOLF_SIMS_SUCCESS",
  GetAboutGolfSimsFailure = "resource/GET_ABOUT_GOLF_SIMS_FAILURE",

  //Resource Relations
  GetResourceRelations = "resource/GET_RESOURCE_RELATIONS",
  GetResourceRelationsSuccess = "resource/GET_RESOURCE_RELATIONS_SUCCESS",
  GetResourceRelationsFailure = "resource/GET_RESOURCE_RELATIONS_FAILURE",
  UpdateResourceRelations = "resource/UPDATE_RESOURCE_RELATIONS",
  UpdateResourceRelationsSuccess = "resource/UPDATE_RESOURCE_RELATIONS_SUCCESS",
  UpdateResourceRelationsFailure = "resource/UPDATE_RESOURCE_RELATIONS_FAILURE",

  //Addon Resource Relations
  GetAddonResourceRelations = "resource/GET_ADDON_RESOURCE_RELATIONS",
  GetAddonResourceRelationsSuccess = "resource/GET_ADDON_RESOURCE_RELATIONS_SUCCESS",
  GetAddonResourceRelationsFailure = "resource/GET_ADDON_RESOURCE_RELATIONS_FAILURE",
  UpdateAddonResourceRelations = "resource/UPDATE_ADDON_RESOURCE_RELATIONS",
  UpdateAddonResourceRelationsSuccess = "resource/UPDATE_ADDON_RESOURCE_RELATIONS_SUCCESS",
  UpdateAddonResourceRelationsFailure = "resource/UPDATE_ADDON_RESOURCE_RELATIONS_FAILURE",

  //Configs
  GetConfigsRequest = "configs/GET",
  GetConfigsSuccess = "configs/GET_SUCCESS",
  GetConfigsFailure = "configs/GET_FAILURE",
  UpdateConfigsRequest = "configs/UPDATE",
  UpdateConfigsSuccess = "configs/UPDATE_SUCCESS",
  UpdateConfigsFailure = "configs/UPDATE_FAILURE",

  //Partners
  GetPartners = 'partners/GET_PARTNERS',
  GetPartnersSuccess = 'partners/GET_PARTNERS_SUCCESS',
  GetPartnersFailure = 'partners/GET_PARTNERS_FAILURE',
  CreatePartner = 'partners/CREATE_PARTNER',
  CreatePartnerSuccess = 'partners/CREATE_PARTNER_SUCCESS',
  CreatePartnerFailure = 'partners/CREATE_PARTNER_FAILURE',
  UpdatePartner = 'partners/UPDATE_PARTNER',
  UpdatePartnerSuccess = 'partners/UPDATE_PARTNER_SUCCESS',
  UpdatePartnerFailure = 'partners/UPDATE_PARTNER_FAILURE',
  DeletePartner = 'partners/DELETE_PARTNER',
  DeletePartnerSuccess = 'partners/DELETE_PARTNER_SUCCESS',
  DeletePartnerFailure = 'partners/DELETE_PARTNER_FAILURE',
  SetPartner = 'partners/SET_PARTNER',
  GetPartner = 'partners/GET_PARTNER',
  GetPartnerSuccess = 'partners/GET_PARTNER_SUCCESS',
  GetPartnerFailure = 'partners/GET_PARTNER_FAILURE',
  SetPartnerError = 'partners/SET_ERROR',

  //Membership
  SelectPlan = 'membership/SELECT_PLANS',
  GetPlans = 'membership/GET_PLANS',
  GetPlansSuccess = 'membership/GET_PLANS_SUCCESS',
  GetPlansFailure = 'membership/GET_PLANS_FAILURE',
  CreatePlan = 'membership/CREATE_PLAN',
  CreatePlanSuccess = 'membership/CREATE_PLAN_SUCCESS',
  CreatePlanFailure = 'membership/CREATE_PLAN_FAILURE',
  UpdatePlan = 'membership/UPDATE_PLAN',
  UpdatePlanSuccess = 'membership/UPDATE_PLAN_SUCCESS',
  UpdatePlanFailure = 'membership/UPDATE_PLAN_FAILURE',
  RemovePlan = 'membership/REMOVE_PLAN',
  RemovePlanSuccess = 'membership/REMOVE_PLAN_SUCCESS',
  RemovePlanFailure = 'membership/REMOVE_PLAN_FAILURE',

  // questions
  GetQuestions = 'questions/GET_QUESTIONS',
  GetQuestionsSuccess = 'questions/GET_QUESTIONS_SUCCESS',
  GetQuestionsFailure = 'questions/GET_QUESTIONS_FAILURE',
  CreateQuestion = 'questions/CREATE_QUESTION',
  CreateQuestionSuccess = 'questions/CREATE_QUESTION_SUCCESS',
  CreateQuestionFailure = 'questions/CREATE_QUESTION_FAILURE',
  UpdateQuestion = 'questions/UPDATE_QUESTION',
  UpdateQuestionSuccess = 'questions/UPDATE_QUESTION_SUCCESS',
  UpdateQuestionFailure = 'questions/UPDATE_QUESTION_FAILURE',
  RemoveQuestion = 'questions/REMOVE_QUESTION',
  RemoveQuestionSuccess = 'questions/REMOVE_QUESTION_SUCCESS',
  RemoveQuestionFailure = 'questions/REMOVE_QUESTION_FAILURE',
  SetQuestion = 'questions/SET_QUESTION',
  GetQuestion = 'questions/GET_QUESTION',
  GetQuestionSuccess = 'questions/GET_QUESTION_SUCCESS',
  GetQuestionFailure = 'questions/GET_QUESTION_FAILURE',
  SetQuestionError = 'questions/SET_ERROR',
  SetFilteredQuestions = 'questions/SET_FILTERED_QUESTIONS',

}
