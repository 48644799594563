import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  IDropdownOption,
  IconButton,
  Icon,
  Toggle,
} from "office-ui-fabric-react";
import {
  updatePackageAction,
  updatePackageAndNavigatePageAction,
  clonePackageAndNavigatePageAction,
  createPackageAndNavigatePageAction,
} from "../../../../../actions/package-action";
import { selectVenuePage } from "../../../../../reducers/auth-reducer";
import { selectPackage, selectIsLoadingPackage, selectPackageError } from "../../../../../reducers/packages";
import { selectTimeSlotDuration } from "../../../../../reducers/settings";
import { selectVenue } from "../../../../../reducers/venues";
import { selectUIConfig } from "../../../../../reducers/ui-reducer";
import { venueConfigUI } from "../../../../../constants/webConfigUI";
import { FeeType, Pricing, ReservationTaxType, State, TripleSeatBlockedStatus, TripleSeatPublishType } from "../../../../../store/types";
import { bufferSlots, formatDuration } from "../../../../../utils/formats";
import { formatLanes } from "../../../../../../../common/utils/formats";
import { selectReadonly } from "../../../../../reducers/auth-reducer";
import { DEFAULT_CARD_COLOR, DEFAULT_TEXT_CARD_COLOR, useViewport } from "../../../../../hooks/responsive";
import FormTextField from "../../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import FormSelectField from "../../../../common/FormSelectField";
import ColorButton from "../../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../../common/components/CancelButton";
import { bookingDurations } from "../../../../../constants/bookingDurationOptions";
import { pricingModelTypes } from "../../../../../constants/pricingModelTypes";
import FormHtmlTextField from "../../../../FormHtmlTextField/FormHtmlTextField";
import { depositTypeList } from "../../../VenueInfo/EditPaymentSettings/EditPaymentSettings";
import "./editPackage.scss";
import { AgeGroup } from "../../../../../constants/packageTypes";
import { parseAgeGroups, serializeAgeGroups } from "../../../../../../../server/src/utils/ageGroups";
import TrashIcon from "../../../../../assets/trash-icon.svgr";

const mapDispatchToProps = {
  updatePackage: updatePackageAction,
  updatePackageAndNavigatePage: updatePackageAndNavigatePageAction,
  createPackageAndNavigatePage: createPackageAndNavigatePageAction,
  clonePackageAndNavigatePage: clonePackageAndNavigatePageAction,
};
const mapStateToProps = (state: State) => ({
  currentPackage: selectPackage(state),
  venue: selectVenue(state),
  readonly: selectReadonly(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  uiConfig: selectUIConfig(state),
  venuePage: selectVenuePage(state),
  isLoadingPackage: selectIsLoadingPackage(state),
  error: selectPackageError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
  isClonePackage: boolean;
};

const EditPackage = ({
  updatePackage,
  currentPackage,
  readonly,
  onClose,
  isClonePackage,
  venue,
  timeSlotDuration,
  uiConfig,
  venuePage,
  isLoadingPackage,
  error,
  updatePackageAndNavigatePage,
  createPackageAndNavigatePage,
  clonePackageAndNavigatePage,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    if (!isLoadingPackage && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingPackage]);
  const { isMobile } = useViewport();
  useEffect(() => {
    setName(currentPackage?.name);
    setPrefix(currentPackage?.prefix || "");
    setDescription(currentPackage?.description);
    setShortDescription(currentPackage?.shortDescription);
    setMethod(currentPackage?.method);
    setNumberOfLanes(currentPackage?.numberOfLanes);
    setCountLanesByGuest(!!currentPackage?.countLanesByGuest);
    setMaxGuestsPerLane(currentPackage?.maxGuestsPerLane);
    setEnableDurationChoice(currentPackage?.enableDurationChoice);
    setDuration(currentPackage?.duration);
    setDurationInSlots(currentPackage?.durationInSlots);
    setMinGuests(currentPackage?.minGuests || 0);
    setMaxGuests(currentPackage?.maxGuests || 0);
    setDeposit(currentPackage?.deposit.toString() || "0.00");
    setDepositType(currentPackage?.depositType || FeeType.PERCENT);
    setLeadTime(currentPackage?.leadTime || 0);
    setShiftingForReservationStart(currentPackage?.shiftingForReservationStart || 0);
    setDaysForBooking(currentPackage?.daysForBooking || 0);
    setPriority(currentPackage?.priority || 0);
    setBufferTimeForReservation(currentPackage?.bufferTimeForReservation);
    setPreBufferTimeForReservation(currentPackage?.preBufferTimeForReservation);
    setEventsEmail(currentPackage?.eventsEmail || "");
  }, [currentPackage]);
  useEffect(() => {
    setName(!isClonePackage ? currentPackage?.name : "");
  }, [isClonePackage]);
  const actionText = uiConfig?.actionText || "Lane";
  const [name, setName] = useState(currentPackage?.name);
  const [prefix, setPrefix] = useState(currentPackage?.prefix || "");
  const [description, setDescription] = useState(currentPackage?.description);
  const [shortDescription, setShortDescription] = useState(currentPackage?.shortDescription);
  const [method, setMethod] = useState(currentPackage?.method);
  const [numberOfLanes, setNumberOfLanes] = useState(currentPackage?.numberOfLanes);
  const [countLanesByGuest, setCountLanesByGuest] = useState(!!currentPackage?.countLanesByGuest);
  const [maxGuestsPerLane, setMaxGuestsPerLane] = useState(currentPackage?.maxGuestsPerLane);
  const [enableDurationChoice, setEnableDurationChoice] = useState(currentPackage?.enableDurationChoice);
  const [duration, setDuration] = useState(currentPackage?.duration);
  const [durationInSlots, setDurationInSlots] = useState(currentPackage?.durationInSlots);
  const [eventsEmail, setEventsEmail] = useState(currentPackage?.eventsEmail || "");
  const [minGuests, setMinGuests] = useState(currentPackage?.minGuests || 0);
  const [maxGuests, setMaxGuests] = useState(currentPackage?.maxGuests || 0);
  const [deposit, setDeposit] = useState(currentPackage?.deposit.toString() || "0.00");
  const [depositType, setDepositType] = useState(currentPackage?.depositType || FeeType.PERCENT);
  const [leadTime, setLeadTime] = useState(currentPackage?.leadTime || 0);
  const [shiftingForReservationStart, setShiftingForReservationStart] = useState(currentPackage?.shiftingForReservationStart || 0);
  const [daysForBooking, setDaysForBooking] = useState(currentPackage?.daysForBooking || 0);
  const [priority, setPriority] = useState(currentPackage?.priority || 0);
  const [bufferTimeForReservation, setBufferTimeForReservation] = useState(currentPackage?.bufferTimeForReservation);
  const [preBufferTimeForReservation, setPreBufferTimeForReservation] = useState(currentPackage?.preBufferTimeForReservation);
  const [ageGroups, setAgeGroups] = useState(parseAgeGroups(currentPackage?.ageGroups || ""));
  const [showPolicy, setShowPolicy] = useState(currentPackage?.showPolicy || false);
  const [packagePolicyText, setPackagePolicyText] = useState(currentPackage?.packagePolicyText || "");
  const [packagePolicyTitle, setPackagePolicyTitle] = useState(currentPackage?.packagePolicyTitle || "");
  const [packagePolicyOverride, setPackagePolicyOverride] = useState(currentPackage?.packagePolicyOverride || false);

  const [errorMinGuests, setErrorMinGuest] = useState("");
  const [errorMaxGuests, setErrorMaxGuest] = useState("");
  const [errorMaxGuestsPerLane, setErrorMaxGuestsPerLane] = useState("");
  const [errorAgeGroups, setErrorAgeGroups] = useState("");
  const [errorEmailNotifications, setErrorEmailNotifications] = useState("");

  const durationList = Array.apply(null, Array(Math.floor(24 * 60 / timeSlotDuration))).map((_, index) => ({
    key: index + 1,
    text: formatDuration(index + 1, timeSlotDuration),
  }));

  const lanesList = Array.apply(null, Array((Math.max(Number(venue?.totalCountOfLanes), 0) || 10) + 1)).map((_, index) => ({
    key: index,
    text: formatLanes(index, actionText),
  }));


  const bufferTimes = bufferSlots(venue?.timeSlotDuration);

  const onAddAgeGroup = () => {
    setAgeGroups([...ageGroups, { groupName: '', abbreviation: '', minQty: 0, maxQty: 0, count: false }]);
  };

  const onRemoveAgeGroup = (index: number) => {
    setAgeGroups(ageGroups.filter((_: any, i: number) => i !== index));
  };

  const onUpdateAgeGroup = (index: number, field: keyof AgeGroup, value: string | number | boolean) => {
    const updatedGroups = ageGroups.map((group: AgeGroup, i: number) =>
      i === index ? { ...group, [field]: value } : group
    );
    setAgeGroups(updatedGroups);
  };


  const isValidPackage = () => {
    if (!(minGuests > 0)) {
      setErrorMinGuest("Minimum guests should be more than 0")
    }
    if (!(maxGuests > 0)) {
      setErrorMaxGuest("Maximum guests should be more than 0")
    }
    if (!(maxGuests >= minGuests)) {
      setErrorMinGuest("Maximum guests should be more than Minimum guests")
      setErrorMaxGuest("Maximum guests should be more than Minimum guests")
    }
    if (countLanesByGuest && !(maxGuestsPerLane && maxGuestsPerLane > 0)) {
      setErrorMaxGuestsPerLane("Max guests per lane should be more than 0")
    }

    ageGroups.forEach(group => {
      if (group.minQty > group.maxQty) {
        setErrorAgeGroups("Max Qty should be more than Min Qty")
      }
      if (group.groupName === "") {
        setErrorAgeGroups("Group Name is required")
      }
    });

    // email notifications validation
    let invalidEmails: string[] = [];
    if (eventsEmail) {
      const emails = eventsEmail.split(",").map(email => email.trim());
      invalidEmails = emails.filter(email => !email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/));
      if (invalidEmails.length > 0) {
        setErrorEmailNotifications("Invalid email address");
      }
    }
    const isAgeGroupsEmpty = ageGroups?.length === 1 && ageGroups[0]?.groupName === "" && isNaN(ageGroups[0]?.minQty) && isNaN(ageGroups[0]?.maxQty);
    const isAgeGroupsValid = ageGroups.every(group => group.minQty <= group.maxQty && group.groupName !== "");
    return (
      name &&
      shortDescription &&
      method &&
      ((!enableDurationChoice && duration) || (enableDurationChoice && durationInSlots)) &&
      minGuests &&
      minGuests > 0 &&
      maxGuests &&
      maxGuests > 0 &&
      maxGuests >= minGuests &&
      (!countLanesByGuest || (countLanesByGuest && maxGuestsPerLane && maxGuestsPerLane > 0)) &&
      venue &&
      (isAgeGroupsEmpty || isAgeGroupsValid)
      && invalidEmails.length === 0
    )
  }

  const getPatch = () => {
    if (!venue) {
      return
    }
    const newDeposit = parseFloat(deposit || "") || 0;
    return {
      name,
      prefix: prefix || "",
      description,
      shortDescription,
      method,
      numberOfLanes: numberOfLanes || 0,
      countLanesByGuest: countLanesByGuest || false,
      maxGuestsPerLane,
      enableDurationChoice,
      duration,
      durationInSlots: (durationInSlots || "").replace(/\s+/g, ""), // remove all spaces
      minGuests,
      maxGuests,
      deposit: newDeposit,
      depositType: depositType || FeeType.PERCENT,
      leadTime,
      shiftingForReservationStart,
      daysForBooking,
      venueId: venue.id,
      taxForServiceCharge: currentPackage?.taxForServiceCharge || "",
      serviceCharge: currentPackage?.serviceCharge || "",
      serviceChargeName: currentPackage?.serviceChargeName || "",
      serviceChargeType: currentPackage?.serviceChargeType || "",
      priority: priority || 0,
      bufferTimeForReservation: bufferTimeForReservation || 0,
      preBufferTimeForReservation: preBufferTimeForReservation || 0,
      reservationTaxes: currentPackage?.reservationTaxes || ReservationTaxType.venueTax,
      cardColor: currentPackage?.cardColor || DEFAULT_CARD_COLOR,
      textCardColor: currentPackage?.textCardColor || DEFAULT_TEXT_CARD_COLOR,
      ageGroups: serializeAgeGroups(ageGroups),
      eventsEmail,
      showPolicy,
      packagePolicyText,
      packagePolicyTitle,
      packagePolicyOverride,
      packageActivities: currentPackage?.packageActivities || [],
      allowMultiActivities: currentPackage?.allowMultiActivities || false,
      activitiesTimeWindow: currentPackage?.activitiesTimeWindow || 0,
      guestsLabel: 'Guests',
      perPersonLabel: ''
    };
  }

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('eventsEmail', eventsEmail)
    setErrorMinGuest("");
    setErrorMaxGuest("");
    setErrorMaxGuestsPerLane("");
    setErrorAgeGroups("");

    if (!isValidPackage()) {
      console.log('is not valid')
      return;
    }

    if (currentPackage) {
      saving.current = true;
      const patch = getPatch();
      isClonePackage
        ? clonePackageAndNavigatePage({ ...currentPackage, ...patch })
        : venuePage === venueConfigUI.PACKAGE_DETAILS
          ? updatePackage({ ...currentPackage, ...patch })
          : updatePackageAndNavigatePage({ ...currentPackage, ...patch });
    } else {
      if (!(name &&
        shortDescription &&
        method &&
        ((!enableDurationChoice && duration) || (enableDurationChoice && durationInSlots)) &&
        minGuests &&
        minGuests > 0 &&
        maxGuests &&
        maxGuests > 0 &&
        maxGuests >= minGuests &&
        (!countLanesByGuest || (countLanesByGuest && maxGuestsPerLane && maxGuestsPerLane > 0)) &&
        venue
      )) {
        return
      }
      saving.current = true;
      const newDeposit = parseFloat(deposit || "") || 0;
      createPackageAndNavigatePage({
        name,
        prefix: prefix || "",
        description: description || "",
        shortDescription,
        tags: '',
        method,
        numberOfLanes: numberOfLanes || 0,
        countLanesByGuest: countLanesByGuest || false,
        maxGuestsPerLane,
        maxGuestsPerLaneForAdmin: undefined,
        enableDurationChoice: enableDurationChoice || false,
        duration: duration || 0,
        durationInSlots: durationInSlots || "",
        durationInSlotsForAdmin: undefined,
        durationInSlotsForMember: undefined,
        minGuests,
        maxGuests,
        minGuestsForAdmin: 0,
        maxGuestsForAdmin: 0,
        deposit: newDeposit,
        depositType: depositType || FeeType.PERCENT,
        leadTime,
        shiftingForReservationStart,
        daysForBooking,
        status: false,
        venueId: venue.id,
        schedulesPackage: [],
        image: '',
        taxForServiceCharge: "",
        serviceCharge: "",
        serviceChargeName: "Service Charge",
        serviceChargeType: "",
        priority: priority || 0,
        parentSchedule: null,
        packageSpecificTripleSeatsIntegration: false,
        tripleSeatRoomId: null,
        tripleSeatLocationId: null,
        tripleSeatAccountId: null,
        tripleSeatContactId: null,
        tripleSeatBlockedStatus: TripleSeatBlockedStatus.Definite,
        tripleSeatStatusesOfPulledEvents: TripleSeatBlockedStatus.Definite,
        tripleSeatPublishType: TripleSeatPublishType.Event,
        tripleSeatReadAvailability: false,
        adminOnly: false,
        allowCancellation: false,
        cancellationFee: 0,
        cancellationFeeType: FeeType.PERCENT,
        cancellationCutOff: 0,
        allowModification: false,
        modificationCutOff: 0,
        modificationFee: 0,
        modificationFeeType: FeeType.PERCENT,
        enableAssignDuration: false,
        assignDuration: '',
        assignDurationForAdmin: '',
        bufferTimeForReservation: bufferTimeForReservation || 0,
        preBufferTimeForReservation: preBufferTimeForReservation || 0,
        syncWithSteltronic: false,
        reservationTaxes: ReservationTaxType.venueTax,
        cardColor: DEFAULT_CARD_COLOR,
        textCardColor: DEFAULT_TEXT_CARD_COLOR,
        enableAboutGolfIntegration: false,
        packageSpecificSeatNinjasIntegration: false,
        splitSeatNinjaReservations: false,
        seatNinjaRestId: null,
        seatNinjaPartyType: null,
        seatninjaPartyFlags: null,
        squareItemIds: [],
        eventsEmail,
        questions: [],
        enableGoTabIntegration: false,
        tripleSeatEventTypeId: null,
        showPolicy,
        packagePolicyText,
        packagePolicyTitle,
        packagePolicyOverride,
        hideDepositLineItem: false,
        frontendOnly: false,
        packageActivities: [],
        allowMultiActivities: false,
        activitiesTimeWindow: 0,
        guestsLabel: 'Guests',
        perPersonLabel: ''
      });
    }
  };

  const onChangeShortDescription = (text?: string) => {
    const newShortDuration = text ? text : "";
    if (newShortDuration.length <= 140) {
      setShortDescription(text);
    } else {
      setShortDescription(newShortDuration.slice(0, 140))
    }
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-package"
    >
      <div className="title h4">
        {isClonePackage ? "Clone" : currentPackage?.id ? "Update" : "Create New"} Package
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="package" onSubmit={onUpdate}>
        <div className="edit-package-fields">
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Package Name"
              className="long-field"
              value={name}
              onChange={(_: any, text?: string) => setName(text)}
              autoComplete="off"
              disabled={readonly}
              required
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Package Prefix"
              className="short-field"
              value={prefix}
              onChange={(_: any, text?: string) => setPrefix(text || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Short Description"
              value={shortDescription}
              multiline rows={2} resizable={false}
              onChange={(_: any, text?: string) => onChangeShortDescription(text)}
              autoComplete="off"
              disabled={readonly}
              required
            />
          </div>
          <div className="row">
            <FormHtmlTextField setValue={setDescription} value={description} readOnly={readonly} label="Long Description" />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
              label="Pricing Method"
              options={pricingModelTypes}
              selectedKey={method}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setMethod(item?.key as Pricing)}
              disabled={readonly}
              required
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Priority"
              type="number"
              value={priority.toString()}
              onChange={(_: any, value?: string) =>
                setPriority(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
              required
            />
          </div>
          {
            method === Pricing.ageGroupsFlatRate || method === Pricing.ageGroupsPerHour ? (
              <div className="row age-group">
                {ageGroups.length > 0 && ageGroups.map((group, index) => (
                  <div className={`fields-mapping ${isMobile ? "mobile" : ""}`} key={index}>
                    <FormTextField
                      label="Group Name"
                      className="field fee-field"
                      value={group.groupName || ""}
                      onChange={(_: any, value?: string) =>
                        onUpdateAgeGroup(index, 'groupName', value || '')
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                    <FormTextField
                      label="Abbreviation"
                      className="field fee-field"
                      value={group.abbreviation || ""}
                      onChange={(_: any, value?: string) =>
                        onUpdateAgeGroup(index, 'abbreviation', value || '')
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                    <FormTextField
                      label="Min Qty"
                      className="field fee-field"
                      value={group.minQty.toString() || ""}
                      onChange={(_: any, value?: string) =>
                        onUpdateAgeGroup(index, 'minQty', value || '')
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                    <FormTextField
                      label="Max Qty"
                      className="field fee-field"
                      value={group.maxQty.toString() || ""}
                      onChange={(_: any, value?: string) =>
                        onUpdateAgeGroup(index, 'maxQty', value || '')
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                    <div>
                      <p className="resource-row-label">Count</p>
                      <Toggle
                        className="inline-toggle"
                        checked={group.count}
                        onChange={() => { onUpdateAgeGroup(index, 'count', !group.count) }}
                      />
                    </div>
                    <IconButton
                      className="delete-button"
                      ariaLabel="Remove Age Group"
                      onClick={() => onRemoveAgeGroup(index)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}
                <div className={isMobile ? "mobile" : ""}>
                  <LinkButton className="add-button" onClick={() => onAddAgeGroup()}>
                    <Icon iconName="Add" className="add" />
                    Add item
                  </LinkButton>
                </div>

                {errorAgeGroups ? <div className="error">
                  {errorAgeGroups}
                </div> : null}
              </div>
            ) : null
          }

          <div className="row">
            <div className={`toggle-button ${countLanesByGuest ? "" : "active"}`} onClick={() => setCountLanesByGuest(false)}>
              <div className={`toggle-button-label ${countLanesByGuest ? "" : "active"}`}>
                Fixed Number of Lanes
              </div>
            </div>
            <div className={`toggle-button ${countLanesByGuest ? "active" : ""}`} onClick={() => setCountLanesByGuest(true)}>
              <div className={`toggle-button-label ${countLanesByGuest ? "active" : ""}`}>
                Flexible Based on Guest Count
              </div>
            </div>
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
              label={`Number of ${actionText}s`}
              options={lanesList}
              selectedKey={numberOfLanes}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setNumberOfLanes(item?.key as number)}
              disabled={readonly || countLanesByGuest}
              required={!countLanesByGuest}
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Max number of guests on one lane"
              type="number"
              errorMessage={errorMaxGuestsPerLane}
              value={maxGuestsPerLane?.toString() || ""}
              onChange={(_: any, value?: string) =>
                setMaxGuestsPerLane(parseInt(value || ""))
              }
              autoComplete="off"
              disabled={readonly || !countLanesByGuest}
              required={countLanesByGuest}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Minimum Guests"
              type="number"
              errorMessage={errorMinGuests}
              value={minGuests.toString()}
              onChange={(_: any, value?: string) =>
                setMinGuests(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
              required
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Maximum Guests"
              type="number"
              errorMessage={errorMaxGuests}
              value={maxGuests.toString()}
              onChange={(_: any, value?: string) =>
                setMaxGuests(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
              required
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Deposit"
              type="number"
              value={deposit.toString()}
              onChange={(_: any, value?: string) =>
                setDeposit(value || "0.00")
              }
              autoComplete="off"
              disabled={readonly}
              required
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormSelectField
              label="Deposit Type"
              options={depositTypeList}
              selectedKey={depositType}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setDepositType(item?.key as FeeType)}
              disabled={readonly}
              required
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Lead Time"
              type="number"
              value={leadTime.toString()}
              onChange={(_: any, value?: string) =>
                setLeadTime(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
              required
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Link skip ahead (in days)"
              type="number"
              value={shiftingForReservationStart.toString()}
              onChange={(_: any, value?: string) =>
                setShiftingForReservationStart(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
              required
            />
          </div>
          <div className="row">
            <div className={`toggle-button ${enableDurationChoice ? "" : "active"}`} onClick={() => setEnableDurationChoice(false)}>
              <div className={`toggle-button-label ${enableDurationChoice ? "" : "active"}`}>
                Set Duration
              </div>
            </div>
            <div className={`toggle-button ${enableDurationChoice ? "active" : ""}`} onClick={() => setEnableDurationChoice(true)}>
              <div className={`toggle-button-label ${enableDurationChoice ? "active" : ""}`}>
                Guest Chooses Duration
              </div>
            </div>
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
              label="Duration"
              options={durationList}
              selectedKey={duration}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => setDuration(item?.key as number)}
              disabled={readonly || enableDurationChoice}
              required={!enableDurationChoice}
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Duration options (in slots)"
              value={durationInSlots}
              onChange={(_: any, text?: string) => setDurationInSlots(text)}
              autoComplete="off"
              disabled={readonly || !enableDurationChoice}
              required={enableDurationChoice}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
              label="Buffer time before reservation"
              options={bufferTimes}
              selectedKey={preBufferTimeForReservation}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption
              ) => setPreBufferTimeForReservation(option?.key ? +option?.key : 0)}
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormSelectField
              label="Buffer time after reservation"
              options={bufferTimes}
              selectedKey={bufferTimeForReservation}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption
              ) => setBufferTimeForReservation(option?.key ? +option?.key : 0)}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
              label="How far in advance can users book?"
              options={bookingDurations}
              selectedKey={daysForBooking}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption
              ) => setDaysForBooking(!!option ? +option?.key : 56)}
              disabled={readonly}
              required
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Email Notifications"
              value={eventsEmail}
              onChange={(_: any, text?: string) => setEventsEmail(text || '')}
              autoComplete="off"
              disabled={readonly}
              errorMessage={errorEmailNotifications}
            />
          </div>
        </div>
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditPackage);
